import React, { useState } from 'react'
import './presentationbox.scss';
import PresentationSection from './presentationsection';
import ReactGA from "react-ga4";

const PresentationboxSingle = (props) => {
   
    const [hidden, setHidden] = useState(false);

    function toggleHide() {
        ReactGA.event({
            category: "Mobile Toggle",
            action: "Hide Toggle Click",
            label: !hidden, // optional
            pageUrl: window.location.href,
            pageTitle: document.title
          });    
        setHidden(!hidden);
    }

    return (
        <div className="presentation-wrapper">
            <div className='hide-button' onClick={toggleHide}>
                <div className={"hide-bg" + (hidden ? " hide" : "")}></div>
                <div className={"hide-option" + (hidden ? "" : " active")}><b>2D</b></div>
                <div className={"hide-option" + (hidden ? " active" : "")}><b>3D</b></div>
            </div>
            <div className="presentation-box single">
                <div className={hidden ?"box-wrapper hidden" : "box-wrapper"}>
                    <div className="presentation-info single">
                        <PresentationSection element={props.contents[0].element}/>
                    </div>
                </div>
            </div>
        </div>
    )
    };

export default PresentationboxSingle