import React from 'react'
import EpicGalleryButton from '../../components/epic-button/epic-gallery-button'

const AppGalleryButton = () => {

    const images = [
        '/youtube/empty.png',
        '/youtube/avatar.png',
        '/youtube/empty.png',
      ];

    const galleryImages = [
        '/gallery/app/app-signup.jpg',
        '/gallery/app/app-menu.jpg',
        '/gallery/app/app-avatar.jpg',
        '/gallery/app/app-profile.jpg',
        '/gallery/app/app-friends.jpg',
        '/gallery/app/app-questions.jpg',
        '/gallery/app/app-territories.jpg',
      ];

    return (
        <EpicGalleryButton images={images} galleryImages={galleryImages} theme="1" text="Crowdfunding App"/>
    )
}

export default AppGalleryButton