import React, { useEffect } from 'react';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import Cookie from '../other/3D/objects/cookie';
import pdfP from "../policies/GLHFPrivacyPolicy.pdf";
import pdfC from "../policies/GLHFCookiePolicy.pdf";

const theme = "new3";

function Privacy() {

    const contents = [
        {
            name: "Privacy",
            icon: "spaceinvader",
            element: <>
                <Infobox title="Policies" text={
                    <>
                        <p>As all companies, we want to know how well we are doing, and cookies help us a lot there. Here's in-depth information about what these are used for, as well as how we handle any other data you provide us.</p>
                        <br/>
                        <a href={pdfP} target="_blank" rel="noreferrer">
                            <button className="glhf-button info-button glow">Privacy Policy</button>
                        </a>
                        <br/>
                        <a href={pdfC} target="_blank" rel="noreferrer">
                            <button className="glhf-button info-button glow">Cookie Policy</button>
                        </a>
                    </>}/>
            </>,
            element3d: <Cookie/>
        }];

    return (        
        <Slide contents={contents} theme={theme}/>
    );
  }
  
export default Privacy;
