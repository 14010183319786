import './newsletter.scss';
import ReactGA from "react-ga4";

const pagehtml = `
    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css" rel="stylesheet" type="text/css">
    <style type="text/css">

    </style>
    <div id="mc_embed_signup">
    <form onsubmit="window.trackSubscribe()" action="https://beer.us14.list-manage.com/subscribe/post?u=98d16b62210c27cf7d27ba2e2&amp;id=11657d9dcf" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
    <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
    <div class="mc-field-group">
        <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
    </label>
        <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL"/>
    </div>
    <div hidden="true"><input type="hidden" name="tags" value="7294454"/></div>
        <div id="mce-responses" class="clear foot">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
        </div>    
        <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input type="text" name="b_98d16b62210c27cf7d27ba2e2_11657d9dcf" tabindex="-1" value=""/>
        </div>
            <div class="optionalParent">
                <div class="clear foot">
                    <input type="submit" onClick="window.newsletterSubmitClick()" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"/>
                </div>
            </div>
        </div>
    </form>
    </div>
    <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
    
    <script type='text/javascript'>
        (function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
    </link>
    `;


function Newsletter() {

    window.newsletterSubmitClick = () => {
        ReactGA.event({
            category: "Newsletter",
            action: "Submit Click",
            label: "Submitted", // optional
            pageUrl: window.location.href,
            pageTitle: document.title
          });    
    }

    return (
        <div className="newsletter-component" dangerouslySetInnerHTML={{ __html: pagehtml }} />
    );
}
    
export default Newsletter;

