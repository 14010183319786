import React, { useState } from 'react'
import './epic-button.scss';
import themeCSS from './epic-button-themes';


const EpicLinkButton = (props) => {

    var backgroundTheme = themeCSS(props.theme);

    return (
        <a href={props.file} className="epic-button-link" target="_blank" rel="noreferrer">
            <div className="epic-button" style={{background: backgroundTheme, backgroundSize: "800%"}}>
                        <div className="image-container">
                            <img className="picture1" src={props.images[0]}/>
                            <img className="picture2" src={props.images[1]}/>
                            <img className="picture3" src={props.images[2]}/>
                    </div>
                <div className="text">{props.text}</div>
            </div>
        </a>
    )
}

export default EpicLinkButton