import React from 'react';
import Slide from '../../components/slide-new/slide';
import Cocacentered from '../other/3D/objects/cocacentered';
import Infobox from '../../components/presentationbox/infobox';
import CanSkins from '../other/3D/objects/can-skins';
import Controller from '../other/3D/objects/controller';
import Owl from '../other/3D/objects/owl2';
import Map from '../other/3D/objects/treasuremap';
import './new-main.scss';
import Trailer from '../other/3D/youtube/premade/trailer';
import Bond from '../other/3D/youtube/premade/bond';
import Interview from '../other/3D/youtube/premade/interview';


import Newsletter from '../../components/newsletter/newsletter';
import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';
import Jeff from '../other/3D/youtube/premade/jeff';
import EpicLinkButton from '../../components/epic-button/epic-link-button';

const theme = "new3";

const docImages = [
    process.env.PUBLIC_URL + '/youtube/google-docs-neg.png',
    process.env.PUBLIC_URL + '/youtube/empty.png',
    process.env.PUBLIC_URL + '/youtube/empty.png',
];

const trailerImages = [
    process.env.PUBLIC_URL + '/youtube/coolperson.png',
    process.env.PUBLIC_URL + '/youtube/philipvformation.png',
    process.env.PUBLIC_URL + '/youtube/german.png',
];

const bondImages = [
    process.env.PUBLIC_URL + './youtube/toplane.png',
    process.env.PUBLIC_URL + './youtube/philipbond.png',
    process.env.PUBLIC_URL + './youtube/elrond.png',
]
const newsImages = [
    process.env.PUBLIC_URL + './youtube/iconspaceshipblack.png',
    process.env.PUBLIC_URL + './youtube/philnews.png',
    process.env.PUBLIC_URL + './youtube/empty.png',
]

const fortniteImages = [
    process.env.PUBLIC_URL + '/youtube/interviewpetter.png',
    process.env.PUBLIC_URL + '/youtube/iconinvader.png',
    process.env.PUBLIC_URL + '/youtube/interviewolof.png',
];

const producerQuitsImages = [
    process.env.PUBLIC_URL + '/youtube/ludvigchair.png',
    process.env.PUBLIC_URL + '/youtube/johandespair.png',
    process.env.PUBLIC_URL + '/youtube/beerbottle.png',
];

const bottleImages = [
    process.env.PUBLIC_URL + '/youtube/bottlenes2.png',
    process.env.PUBLIC_URL + '/youtube/bottleanimation2.png',
    process.env.PUBLIC_URL + '/youtube/bottlejap2.png',
];

const wrapImages = [
    process.env.PUBLIC_URL + '/youtube/philipcamera.png',
    process.env.PUBLIC_URL + '/youtube/johanpetterhug.png',
    process.env.PUBLIC_URL + '/youtube/german2.png',
];

const JeffImages = [
    process.env.PUBLIC_URL + '/youtube/jeffsign.png',
    process.env.PUBLIC_URL + '/youtube/jeff.png',
    process.env.PUBLIC_URL + '/youtube/johansceptic.png',
];

const contents = [
    {
        name: "Design",
        icon: "spaceinvader",
        element: <Infobox title="Design" text={
        <>
            <p>We're looking to create a mature, down to earth and awesome beverage brand for gamers. Here are some documents containing graphical outlines as well as our thoughts behind them. Don't take them too seriously but take them very seriously, if you know what I mean.</p>
            <EpicLinkButton images={docImages} theme="1" text="Brand Identity" file="/docs/GLHF-Brand-Identity.pdf"/>
            <EpicLinkButton images={docImages} theme="2" text="Design Principles" file="/docs/GLHF-Design-Principles.pdf"/>
            <EpicLinkButton images={docImages} theme="3" text="Brand Values" file="/docs/GLHF-Brand-Values.pdf"/>
       </>}/>,
        element3d: <CanSkins/>
    },
    {
        name: "Feel",
        icon: "beer",
        element: <Infobox title="Feel" text={
        <>
            <p>We love quality and we love playfullness. Feel free to throw in some memes or easter eggs :D</p>
            <EpicYoutubeButton images={trailerImages} theme="1" text="Epic Trailer!" youtubeId="angbWa4bslU"/>
            <EpicYoutubeButton images={bondImages} theme="2" text="Board Gamers" youtubeId="zn5LWdKlGTU"/>
            <EpicYoutubeButton images={fortniteImages} theme="3" text="Meet us!" youtubeId="bStItpN1h_Q"/>
        </>}/>,
        element3d: <Trailer/>
    },
];

function Design() {

    return (
        <Slide contents={contents} theme={theme}/>
    );
  }
  
export default Design;
