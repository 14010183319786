import React from 'react';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import CanSkins from '../other/3D/objects/can-skins';
import './new-main.scss';
import Trailer from '../other/3D/youtube/premade/trailer';
import Cocacentered from '../other/3D/objects/cocacentered';

import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';
import EpicLinkButton from '../../components/epic-button/epic-link-button';

const theme = "new3";

const docImages = [
    process.env.PUBLIC_URL + '/youtube/google-docs-neg.png',
    process.env.PUBLIC_URL + '/youtube/empty.png',
    process.env.PUBLIC_URL + '/youtube/empty.png',
];

const trailerImages = [
    process.env.PUBLIC_URL + '/youtube/coolperson.png',
    process.env.PUBLIC_URL + '/youtube/philipvformation.png',
    process.env.PUBLIC_URL + '/youtube/german.png',
];

const bondImages = [
    process.env.PUBLIC_URL + './youtube/toplane.png',
    process.env.PUBLIC_URL + './youtube/philipbond.png',
    process.env.PUBLIC_URL + './youtube/elrond.png',
]
const newsImages = [
    process.env.PUBLIC_URL + './youtube/iconspaceshipblack.png',
    process.env.PUBLIC_URL + './youtube/philnews.png',
    process.env.PUBLIC_URL + './youtube/empty.png',
]

const fortniteImages = [
    process.env.PUBLIC_URL + '/youtube/interviewpetter.png',
    process.env.PUBLIC_URL + '/youtube/iconinvader.png',
    process.env.PUBLIC_URL + '/youtube/interviewolof.png',
];

const producerQuitsImages = [
    process.env.PUBLIC_URL + '/youtube/ludvigchair.png',
    process.env.PUBLIC_URL + '/youtube/johandespair.png',
    process.env.PUBLIC_URL + '/youtube/beerbottle.png',
];

const bottleImages = [
    process.env.PUBLIC_URL + '/youtube/bottlenes2.png',
    process.env.PUBLIC_URL + '/youtube/bottleanimation2.png',
    process.env.PUBLIC_URL + '/youtube/bottlejap2.png',
];

const wrapImages = [
    process.env.PUBLIC_URL + '/youtube/philipcamera.png',
    process.env.PUBLIC_URL + '/youtube/johanpetterhug.png',
    process.env.PUBLIC_URL + '/youtube/german2.png',
];

const JeffImages = [
    process.env.PUBLIC_URL + '/youtube/jeffsign.png',
    process.env.PUBLIC_URL + '/youtube/jeff.png',
    process.env.PUBLIC_URL + '/youtube/johansceptic.png',
];

const contents = [
    {
        name: "Social Media & Community",
        icon: "pacman",
        element: <Infobox title="Social Media & Community" text={
        <>
            <p>We're looking to complete our team with skills in <b>social media management</b> and <b>community management</b> for our upcoming company launch and crowdfunding campaign. We aim to launch right after summer when a complete team has been assembled.</p>
            <p>If you think you should be part of the solution, let me know at <b>petter@glhf.beer!</b></p>
            <p>Click the icons below for in-depth information about the positions and the company.</p>
        </>}/>,
        element3d: <Trailer/>
    },
    {
        name: "LFM SM & CM",
        icon: "sword",
        element: <Infobox title="LFM SM & CM" text={
        <>
            <p>We need a social media manager and a community manager. Preferably Stockholm-based, but you can work from anywhere you like as long as everything works. The position could be filled by:</p>
            <ul>
                <li>One person</li>
                <li>Two people</li>
                <li>A team of juniors and one senior.</li>
                <li>A covenant of media witches</li>
                <li>Any other viable solution</li>
            </ul>
            <p>Here's more indepth information about the positions and the company:</p>
            <EpicLinkButton images={docImages} theme="1" text="Complete specification" file="/docs/Social-Media-Community-Management.pdf"/>
        </>}/>,
        element3d: <Cocacentered/>
    },
    {
        name: "Guidelines",
        icon: "spaceinvader",
        element: <Infobox title="Guidelines and Tonality" text={
        <>
            <p>We're looking to create a mature, down to earth and awesome beverage brand for gamers. Here are some outlines, thoughts and values. Don't take them too seriously but take them quite seriously, if you know what I mean.</p>
            <br/>
            <EpicLinkButton images={docImages} theme="3" text="Brand Values" file="/docs/GLHF-Brand-Values.pdf"/>
            <EpicLinkButton images={docImages} theme="1" text="Brand Identity" file="/docs/GLHF-Brand-Identity.pdf"/>
       </>}/>,
        element3d: <Trailer/>
    },
];

function Community() {

    return (
        <Slide contents={contents} theme={theme}/>
    );
  }
  
export default Community;
