/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: GremorySaiyan (https://sketchfab.com/GremorySaiyan)
license: SKETCHFAB Standard (https://sketchfab.com/licenses)
source: https://sketchfab.com/3d-models/owl-animation-fly-2d1d4cdd118b4f929c61b13a05909bd4
title: Owl Animation Fly
*/

import React, { useRef } from 'react'
import { PresentationControls } from '@react-three/drei'
import { useLoader, useFrame, useThree } from '@react-three/fiber';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Vector3, Clock, AnimationMixer, FrontSide, DoubleSide } from 'three';

const time = new Clock();

const Model = props => {
  const model = useLoader(
    GLTFLoader,
    'owl_animation_fly/scene.gltf'
)
const modelParch = useLoader(
  GLTFLoader,
  'parchment/scene.gltf'
)

    // Here's the animation part
    // ************************* 
    // let mixer
    // if (model.animations.length) {
    //     mixer = new THREE.AnimationMixer(model.scene);
    //     model.animations.forEach(clip => {
    //         const action = mixer.clipAction(clip)
    //         action.play();
    //     });
    // }
    const { camera } = useThree();

    let mixer = new AnimationMixer(model.scene);
    const action = mixer.clipAction(model.animations[0])
    action.play();
    mixer?.update(0.06)


    const parch = useRef();
    const presGroup = useRef();

    model.materials.Owl_MI.transparent = true;
    modelParch.materials.Page.transparent = true;
    model.materials.Owl_MI.opacity = 0;
    modelParch.materials.Page.opacity = 0;

    const scenePos = new Vector3(
      props.position[0],
      props.position[1],
      props.position[2],
    )

    useFrame((state, delta) => {
  
      if (props.introAnimation.done == false) return;

      const mesh1Position = camera.position;
      const mesh2Position = scenePos;
      const distance = mesh1Position.distanceTo(mesh2Position);

      var o = 0;
      if (distance < props.fadeStart) o = 1; 
      if (distance > props.fadeEnd) o = 0;
      if (distance > props.fadeStart && distance < props.fadeEnd) o = 1 - (distance - props.fadeStart) / (props.fadeEnd - props.fadeStart);
  
      model.materials.Owl_MI.opacity = o;
      modelParch.materials.Page.opacity = o;
  
      if (o == 0) return;

      let x = Math.sin(time.getElapsedTime() * 0.7 - 0.2) / 4 + Math.sin(time.getElapsedTime() * 0.6 - 0.2) / 4;
      let y = Math.sin(time.getElapsedTime() * 0.25) / 20;
      let z = Math.sin(time.getElapsedTime() * 0.7 - 0.2) / 3;
  
      presGroup.current.position.set(x / 2, y / 2, z / 2);
  
      let xRot = -0.7 + Math.sin(time.getElapsedTime() * 0.3) / 10;
      let yRot = 0.7 + Math.sin(time.getElapsedTime() * 0.5) / 10;
      presGroup.current.rotation.set(xRot, yRot, 0);
    })
    // *************************

    model.scene.traverse(child => {
        if (child.isMesh) {
            child.castShadow = true
            child.receiveShadow = true
            child.material.side = FrontSide
        }
    })

    
    modelParch.scene.traverse(child => {
      if (child.isMesh) {
          child.castShadow = true
          child.receiveShadow = true
          child.material.side = DoubleSide
      }
  })

    return (
    <group position={props.position}>
      <PresentationControls
      global={false} // Spin globally or by dragging the model
      cursor={true} // Whether to toggle cursor style on drag
      snap={false} // Snap-back to center (can also be a spring config)
      speed={2.5} // Speed factor
      zoom={1} // Zoom factor when half the polar-max is reached
      rotation={[0, 0, 0]} // Default rotation
      polar={[-Infinity, Infinity]} // Vertical limits
      azimuth={[-Infinity, Infinity]} // Horizontal limits
      config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
    >
      <group ref={presGroup} scale={[1.3, 1.3, 1.3]}>
        <primitive 
            object={model.scene}
            scale={[0.006, 0.006, 0.006]}
            rotation={[0.0, 0, 0]}
            position={[1.5, 0, -3]}
        />

        <primitive 
            object={model.scene}
            scale={[0.1, 0.1, 0.1]}
            position={[0,0,0]}
            rotation={[0.0, 0.0, 0]}
        />
        <primitive ref={parch}
          object={modelParch.scene}
          rotation={[0.4, -0.14, 0.0]}
          position={[0,-0.8,-2.1]}
          scale={[0.003, 0.003, 0.003]}
        />
      </group>
      </PresentationControls>

      </group>

    )
}

export default Model;
