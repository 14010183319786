import React from 'react'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import ReactGA from "react-ga4";
import ImageComponent from '../image-component/image-component';
import themeCSS from '../epic-button/epic-button-themes';

const clickCounter = {count: 0}

const Menubutton = (props) => {

    const scrollToId = `section${props.i}`;

    const handleClickNav = () => {

        props.setActiveButton(props.i);
        scrollTo();      

        clickCounter.count++;

        ReactGA.event({
            category: "Page Menu",
            action: "Menu Click",
            label: props.name, // optional
            value: clickCounter.count,
            pageUrl: window.location.href,
            pageTitle: document.title
          });

        // pixels
        window.trackViewContent();
    }

    function scrollTo() {       
        scrollIntoView(document.getElementById(scrollToId), {
            duration: props.slideAnimationDuration,
            ease: t =>
            t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
        });
    }

    const classes = props.i == props.activeButton ? 'menu-button active' : 'menu-button';

    var backgroundTheme = props.i == props.activeButton ? themeCSS("1") : "none";

    return (
        <div className={classes} id={"button" + props.i} style={{backgroundImage: backgroundTheme, backgroundSize: "800%"}} onClick={handleClickNav}>
            <div className="menu-button-tooltip">{props.name}</div>
            <ImageComponent 
                className="menu-button-icon" 
                src={"/iconz/pond/" + props.icon + ".png"} 
                endingOpacity={props.i === props.activeButton ? 1 : 0.8} 
                translateX={-50}
                translateY={-50}
            />
        </div>
    )
}

export default Menubutton