/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { Clock, MeshStandardMaterial } from 'three'
import { useSpring } from '@react-spring/three';

const time = new Clock();

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/playbutton.gltf')

  const [{scale}, scaleApi]  = useSpring(() => (
    { 
      scale: 1.0,
    }))

  function over() {
    document.body.style.cursor = 'pointer'
    scaleApi.start({scale: 1.1})
  }

  function out() {
    document.body.style.cursor = 'auto'
    scaleApi.start({scale: 1.0})
  }

  useFrame(() => {
      group.current.scale.set(scale.get(), scale.get(), scale.get());
      materialButton.opacity = props.opacity.value;
      materialButton2.opacity = props.opacity.value;
  })

  const materialButton = new MeshStandardMaterial({
    color:"#FF1111",
    roughness: 0.7,
    envMapIntensity: 0.3,
    transparent: true,
    opacity: 1,
  })
  const materialButton2 = new MeshStandardMaterial({
    color:"#FFFFFF",
    roughness: 0.7,
    envMapIntensity: 0.3,
    transparent: true,
    opacity: 1,
  })

  return (
    <group ref={group} {...props} dispose={null} 
      position={[0, -2.3, 0]} 
      scale={[1.0, 1.0, 1.0]}
      onPointerOver={over} onPointerOut={out}
    >
      <mesh geometry={nodes.Red_part.geometry} material={materialButton} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.White_part.geometry} material={materialButton2} position={[0, 0, 0.37]} rotation={[Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('/playbutton.gltf')
