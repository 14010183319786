import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { BrowserRouter as Router, useRoutes, useLocation } from 'react-router-dom';
import routes from './routes.js';

const UseRoutes = (props) => {

    let endroutes = useRoutes(props.routes);
    return endroutes;
}

function PageRouting() {
    
    const location = useLocation();

    window.dataLayer.push({
        event: 'pageview'
      });

    return (
        <AnimatePresence exitBeforeEnter>
            <UseRoutes location={location} routes={routes}/>
        </AnimatePresence>
    );
}
  
export default PageRouting;