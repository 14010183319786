import React from 'react';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import CanSkins from '../other/3D/objects/can-skins';
import './new-main.scss';
import Trailer from '../other/3D/youtube/premade/trailer';
import Bond from '../other/3D/youtube/premade/bond';
import Interview from '../other/3D/youtube/premade/interview';
import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';
import Jeff from '../other/3D/youtube/premade/jeff';

const theme = "new3";

const trailerImages = [
    process.env.PUBLIC_URL + '/youtube/coolperson.png',
    process.env.PUBLIC_URL + '/youtube/philipvformation.png',
    process.env.PUBLIC_URL + '/youtube/german.png',
];

const bondImages = [
    process.env.PUBLIC_URL + './youtube/toplane.png',
    process.env.PUBLIC_URL + './youtube/philipbond.png',
    process.env.PUBLIC_URL + './youtube/elrond.png',
];

const newsImages = [
    process.env.PUBLIC_URL + './youtube/iconspaceshipblack.png',
    process.env.PUBLIC_URL + './youtube/philnews.png',
    process.env.PUBLIC_URL + './youtube/empty.png',
];

const fortniteImages = [
    process.env.PUBLIC_URL + '/youtube/interviewpetter.png',
    process.env.PUBLIC_URL + '/youtube/iconinvader.png',
    process.env.PUBLIC_URL + '/youtube/interviewolof.png',
];

const producerQuitsImages = [
    process.env.PUBLIC_URL + '/youtube/ludvigchair.png',
    process.env.PUBLIC_URL + '/youtube/johandespair.png',
    process.env.PUBLIC_URL + '/youtube/beerbottle.png',
];

const bottleImages = [
    process.env.PUBLIC_URL + '/youtube/bottlenes2.png',
    process.env.PUBLIC_URL + '/youtube/bottleanimation2.png',
    process.env.PUBLIC_URL + '/youtube/bottlejap2.png',
];

const wrapImages = [
    process.env.PUBLIC_URL + '/youtube/philipcamera.png',
    process.env.PUBLIC_URL + '/youtube/johanpetterhug.png',
    process.env.PUBLIC_URL + '/youtube/german2.png',
];

const JeffImages = [
    process.env.PUBLIC_URL + '/youtube/jeffsign.png',
    process.env.PUBLIC_URL + '/youtube/jeff.png',
    process.env.PUBLIC_URL + '/youtube/johansceptic.png',
];


const contents = [
    {
        name: "Öl 2.0",
        icon: "beer",
        element: <Infobox title="Beer 2.0" text={
        <>
            <EpicYoutubeButton images={bottleImages} theme="2" text="AR Beer" youtubeId="Fwee970BoHk"/>
            <p>Vi är världens första digitala öl. Med vår app låser du upp en helt ny dimension till din öl-upplevelse.</p>
       </>}/>,
        element3d: <CanSkins/>
    },
    {
        name: "Promo Trailer",
        icon: "sword",
        element: <Infobox title="Promo Trailer" text={
        <>
            <EpicYoutubeButton images={trailerImages} theme="1" text="Episk Trailer!" youtubeId="angbWa4bslU"/>
            <p>I <b>slutet av året</b> lanserar vi vår crowdfunding-kampanj. Vi går ut brett på många fronter, men allt leds av vår crowdfunding-trailer som toppar vår Indiegogo-sida.</p></>}/>,
        element3d: <Trailer/>
    },
    {
        name: "Segmentsfilm",
        icon: "spaceship",
        element: <Infobox title="Undersegment" text={
        <>
            <p>Vi har delat upp gamers i undersegment, och skapar nu riktad reklam och content för varje undergrupp. De finns och bemöts alla på olika plattformar.</p>
            <br/>
            <EpicYoutubeButton images={bondImages} theme="1" text="Brädspelare" youtubeId="zn5LWdKlGTU"/>
            <EpicYoutubeButton images={newsImages} theme="2" text="Soffgrabbarna" youtubeId="Jcrk5PY_FmQ"/>
        </>}/>,
        element3d: <Bond/>
    },
    {
        name: "Social Media",
        icon: "spaceinvader",
        element: <Infobox title="Social Media" text={
        <>
            <p>Community är allt för oss. Vi lever vår livsstil och delar med oss av hela vår resa på sociala medier.</p>
            <EpicYoutubeButton images={fortniteImages} theme="1" text="Meet us!" youtubeId="bStItpN1h_Q"/>
            <EpicYoutubeButton images={producerQuitsImages} theme="2" text="Producer quits!" youtubeId="tevbBxsLmLw"/>
            <EpicYoutubeButton images={wrapImages} theme="3" text="Almost there!" youtubeId="KtDyK2g4I04"/>
        
        </>}/>,
        element3d: <Interview/>
    },
    {
        name: "Youtube",
        icon: "pacman",
        element: <Infobox title="Youtube" text={
        <>
            <p>Vi gör inte reklam, vi gör content. Vår youtube-kanal kommer att fungera precis som vilken gaming-kanal som helst, där företaget och vi som jobbar med GLHF kommer att synas personligen och där man bygger en relation till oss och brandet.</p>
            <EpicYoutubeButton images={JeffImages} theme="1" text="Jeff the Intern" youtubeId="BFowbn-CD9I"/>
        </>}/>,
        element3d: <Jeff/>
    },

];

function Spendrups() {

    return (
        <Slide contents={contents} theme={theme}/>
    );
  }
  
export default Spendrups;
