/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useState, useCallback } from 'react'
import { useGLTF, useTexture, PresentationControls } from '@react-three/drei'
import { useFrame, useThree } from "@react-three/fiber";
import { MeshStandardMaterial, Clock, sRGBEncoding, TextureLoader, Texture, MeshBasicMaterial } from 'three';
import { useSpring } from '@react-spring/three';

const time = new Clock();

var t = {value: null};

const ChangeableCan = ({ beerChanger, ...props }) => {

  const group = useRef();
  const { camera } = useThree();

  const opacity = {value: 0};

  const texture = useTexture("/canlabels/defaultsmall.jpg", (texture) => {
    texture.encoding = sRGBEncoding;
    texture.flipY = false
  });

  useEffect(() => {
    beerChanger.changePreview = (beer) => {

      const newTexture = new TextureLoader().load( beer.label );
      newTexture.encoding = sRGBEncoding;
      newTexture.flipY = false

      t.value = newTexture;
    }

  }, []);

  useFrame(() => {

    if (props.introAnimation.done == false) return;

    const mesh1Position = camera.position;
    const mesh2Position = group.current.position;
    const distance = mesh1Position.distanceTo(mesh2Position);
    
    var o = 0;
    if (distance < props.fadeStart) o = 1; 
    if (distance > props.fadeEnd) o = 0;
    if (distance > props.fadeStart && distance < props.fadeEnd) o = 1 - (distance - props.fadeStart) / (props.fadeEnd - props.fadeStart);

    opacity.value = o;
  })

  const { nodes } = useGLTF('/cocacentered.gltf');

  return (
    <group {...props} dispose={null} ref={group} position={props.position}>
        <PresentationControls
          global={false} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={false} // Snap-back to center (can also be a spring config)
          speed={2.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[0, 0, 0]} // Default rotation
          polar={[-Infinity, Infinity]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        >
          <Can introAnimation={props.introAnimation} opacity={opacity} geometry={nodes.Cylinder001.geometry} texture={t} defaultTexture={texture}/>
        </PresentationControls>
    </group>
  )
};

const Can = (props) => {

  const [{offset}, setSpeed] = useSpring(() => ({ offset: 0 }))
  const can = useRef();

  const mat = new MeshStandardMaterial ({
    metalness: 0.6,
    roughness: 0.38,
    envMapIntensity: 0.8,
    map: props.defaultTexture,
    transparent: true,
    opacity: 0,
  })

  useFrame(() => {

    if (props.texture.value == null) return;

    if (mat.map != props.texture.value)
    {
      mat.map = props.texture.value;
      offset.set(1);
      offset.start(0); 
    }  
    
  })

  useFrame((state) => {

    if (props.introAnimation.done == false) return;

    mat.opacity = props.opacity.value;
    if (props.opacity.value == 0) return;

    let defaultYRotationOffset = 0;
    if (props.texture.value == null) defaultYRotationOffset = 1.8;

    let x = 0 + Math.sin(time.getElapsedTime() * 1.4 - 0.2) / 40 + Math.sin(time.getElapsedTime() * 1.1 - 0.2) / 40 - offset.get() / 12;
    let y = 0 + Math.sin(time.getElapsedTime() * 0.5) / 20;
    let z = 0 + Math.sin(time.getElapsedTime() * 1.4 - 0.2) / 30 - offset.get() / 12;

    can.current.position.set(x, y, z);

    let yRot = -1.9 + Math.sin(time.getElapsedTime() * 0.5) / 2 + defaultYRotationOffset;
    can.current.rotation.set(0, yRot, 0);
  })

  useEffect(() => {
    const handleDrop = (event) => {
      event.preventDefault();
      console.log(event.dataTransfer.files);
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        const file = event.dataTransfer.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const texture = new Texture(img);
            texture.encoding = sRGBEncoding;
            texture.flipY = false
            texture.needsUpdate = true;
            t.value = texture;
          };
        };
      }
    };

    const handleDragOver = (event) => {
      event.preventDefault();
    };

    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragover', handleDragOver);

    return () => {
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  return (
      <mesh ref={can} material={mat} geometry={props.geometry}  scale={0.7}></mesh>
  )
}


export default ChangeableCan;

useGLTF.preload('/cocacentered.gltf')
