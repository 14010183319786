
const themeCSS = (theme) => {

    switch (theme)
    {
        case "1":
            return "linear-gradient(90deg, #ffe033, #ff00ff, #fffb00, #ff9800, #ffe033)";
        case "2":
            return "linear-gradient(90deg, #00f8bf, #335ecd, #e912fc, #0094ae, #00f8bf)";
        case "3":
            return "linear-gradient(90deg, #ff00ff, #ff0000, #ff00c5, #ff005b, #ff00ff)";
        case "4":
            return "linear-gradient(90deg, #3f3ecd, #af3cf2, #0089b7, #af3cf2, #3f3ecd)";
    }
};

export default themeCSS;




