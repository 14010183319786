import React, { useState, useEffect } from "react";

const ImageComponent = ({ className, src, endingOpacity, translateX, translateY, rotate }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => setLoaded(true);
  }, [src]);

  const animationDuration = 0.7;

  const baseStyle = {
    opacity: 0,
    transform: `scale(0.8) rotate(${-5 + rotate}deg) translate(${translateX}%, ${translateY}%)`,
    transition: `opacity ${animationDuration}s ease, transform ${animationDuration}s ease`
  };

  const loadedStyle = {
    opacity: endingOpacity,
    transform: `scale(1) rotate(${0 + rotate}deg) translate(${translateX}%, ${translateY}%)`
  };

  const style = loaded ? { ...baseStyle, ...loadedStyle } : baseStyle;

  return <img className={className} src={src} style={style} alt="" />;
};

ImageComponent.defaultProps = {
  endingOpacity: 1,
  translateX: 0,
  translateY: 0,
  rotate: 0
};

export default ImageComponent;
