import './slide.scss';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import RenderTest from '../../contents/other/3D/rendertest';
import Presentationbox from '../presentationbox/presentationbox';
import PresentationboxSingle from '../presentationbox/presentationbox-single';
import { useSpring } from '@react-spring/three';
import ImageComponentSimple from '../image-component/image-component-simple';

const slideAnimationDuration = 1500;

function Slide(props) {
    
    const location = useLocation();

    const percentage = {value: 0};

    const [{percentageSpring}, percentageApi]  = useSpring(() => (
        { 
            percentageSpring : 0
        }))

    function scrollTo(i)
    {
        var toPerc = 100 / (props.contents.length - 1) * i;
        percentageApi.start(
            {
              to: {
                percentageSpring: toPerc,
              },
              config: {
                duration: slideAnimationDuration,
                easing: t =>
                    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
              },
            });
    }

    //const [percentage, setPercentage] = useState(0);

    var bg1 = '/themes/' + props.theme + '/background1.jpg';

    return (
            <div className="slide" key={location.key + "slide"}>
                <ImageComponentSimple key={location.key  + "bg1"} alt="" src={bg1} className="bg-layers bg1 fade-in"/>
                <div className="canvas-container">
                    <RenderTest percentage={percentage} percentageSpring={percentageSpring} contents={props.contents}/>
                </div>
                <div className="flex-wrapper" style={{ zIndex: 10 }}>
                    <div className="content-wrapper" key={location.key + "left"}>
                    </div>
                    <div className="content-wrapper" key={location.key + "right"}>
                        {(props.hasOwnProperty("single") && props.single == true) ?
                            <PresentationboxSingle scrollTo={scrollTo} slideAnimationDuration={slideAnimationDuration} contents={props.contents}/>
                            :
                            <Presentationbox scrollTo={scrollTo} slideAnimationDuration={slideAnimationDuration} contents={props.contents}/>
                        }
                    </div>
                </div>
            </div>
    );
}

export default Slide;