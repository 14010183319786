import React, { useRef } from 'react'
import { PresentationControls, useGLTF, useTexture } from '@react-three/drei'
import { MeshStandardMaterial } from 'three';
import { useFrame } from '@react-three/fiber';

const BlastBarrell = ({objectPositions}) => {

    const { nodes } = useGLTF('/arskins/blastbarrell/barrell.gltf')
    const model = useRef();
    const groupRef = useRef();

    const texture = useTexture("/arskins/blastbarrell/barrellalbedo.jpg", (texture) => {
        //texture.encoding = sRGBEncoding;
        texture.flipY = true
      });
    
      const material = new MeshStandardMaterial({
        color:"#FFFFFF",
        metalness: 0.8,
        roughness: 0.35,
        envMapIntensity: 0.7,
        map: texture,
        transparent: true,
        //depthWrite: false,
      })
    

    useFrame(({ clock }) => {

        model.current.rotation.y = -clock.getElapsedTime() / 20;
        groupRef.current.position.x = objectPositions.skin[0];
        groupRef.current.position.y = objectPositions.skin[1];
        groupRef.current.position.z = objectPositions.skin[2];

    });
    
  return (
    <group ref={groupRef}>
        <PresentationControls
          global={false} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={false} // Snap-back to center (can also be a spring config)
          speed={2.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[0, 0, 0]} // Default rotation
          polar={[-Infinity, Infinity]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        >
            <mesh ref={model} geometry={nodes.barrelobj.geometry} material={material} rotation={[Math.PI / 2, 0, 0 ]} scale={0.3} position={[0, 0, 0]} />
        </PresentationControls>
    </group>
  )
}

useGLTF.preload('/arskins/blastbarrell/barrell.gltf')
export default BlastBarrell