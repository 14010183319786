import NewMain from './pages/new-main'
import Easter from './pages/easter'
import Gatekeeper from './pages/gatekeeper'
import Redirect from './other/redirect';
import Spendrups from './pages/spendrups';
import Privacy from './pages/privacy';
import App from './pages/app';
import Content from './pages/content';
import Design from './pages/design';
import Deck from './pages/deck';
import Craft from './pages/craft';
import Artists from './pages/artists';
import Preview from './pages/preview';
import GoPreview from './pages/gopreview';
import Community from './pages/community';
//import AnimeCanvas from './other/3D/animecanvas';


const routes =
[
    {
        path: "*",
        name: "",
        element: <NewMain path="/" />
    },
    {
        path: "/noapp",
        name: "rolled",
        element: <Redirect to={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} />
    },
    {
        path: "/app",
        name: "app",
        element: <App />
    },    
    {
        path: "/artists",
        name: "artists",
        element: <Artists />
    },
    {
        path: "/community",
        name: "community",
        element: <Community />
    },
    {
        path: "/collaborate",
        name: "collaborate",
        element: <Gatekeeper />
    },      
    {
        path: "/privacy",
        name: "policies",
        element: <Privacy />
    },      
    {
        path: "/cookies",
        name: "policies",
        element: <Privacy />
    },      
    {
        path: "/policies",
        name: "policies",
        element: <Privacy />
    },      
    {
        path: "/spendrups",
        name: "spendrups",
        element: <Spendrups />
    },  
    {
        path: "/deck",
        name: "deck",
        element: <Deck />
    },   
    {
        path: "/content",
        name: "content",
        element: <Content />
    },   
    {
        path: "/design",
        name: "design",
        element: <Design />
    },   
    {
        path: "/iddqd",
        name: "easter",
        element: <Easter />
    },
    {
        path: "/idkfa",
        name: "easter",
        element: <Easter />
    },
    {
        path: "/idclip",
        name: "easter",
        element: <Easter />
    },
    {
        path: "/craft",
        name: "craftbeer",
        element: <Craft />
    },
    {
        path: "/preview",
        name: "preview",
        element: <Preview />
    },
    {
        path: "/go",
        name: "go preview",
        element: <GoPreview />
    },        

];

export default routes;