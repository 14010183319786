/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: sander-ndreca (https://sketchfab.com/sander-ndreca)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/controller-fami-com-nes-e1be84e64d144366af279ed6b19c7662
title: Controller Fami Com NES
*/

import React, { useRef } from 'react'
import { useGLTF, PresentationControls } from '@react-three/drei'
import { useFrame, useThree } from "@react-three/fiber";
import { Clock } from 'three';

const time = new Clock();

export default function Model({ ...props }) {
  const group = useRef()
  const presGroup = useRef()
  const { nodes, materials } = useGLTF('/controller/scene.gltf')
  const { camera } = useThree();

  materials.RootNode.transparent = true;
  materials.Nuovo.transparent = true;
  materials.Testa_Cavo.transparent = true;
  materials.Controller.transparent = true;

  materials.RootNode.opacity = 0;
  materials.Nuovo.opacity = 0;
  materials.Testa_Cavo.opacity = 0;
  materials.Controller.opacity = 0;

  useFrame(() => {

    if (props.introAnimation.done == false) return;

    const mesh1Position = camera.position;
    const mesh2Position = group.current.position;
    const distance = mesh1Position.distanceTo(mesh2Position);
    
    let opacity = 0;
    if (distance < props.fadeStart) opacity = 1; 
    if (distance > props.fadeEnd) opacity = 0;
    if (distance > props.fadeStart && distance < props.fadeEnd) opacity = 1 - (distance - props.fadeStart) / (props.fadeEnd - props.fadeStart);

    materials.RootNode.opacity = opacity;
    materials.Nuovo.opacity = opacity;
    materials.Testa_Cavo.opacity = opacity;
    materials.Controller.opacity = opacity;

    if (opacity == 0) return;

    let x = Math.sin(time.getElapsedTime() * 1.4 - 0.2) / 4 + Math.sin(time.getElapsedTime() * 1.1 - 0.2) / 4;
    let y = Math.sin(time.getElapsedTime() * 0.5) / 20;
    let z = Math.sin(time.getElapsedTime() * 1.4 - 0.2) / 3;

    presGroup.current.position.set(x * 5 - 50, y * 1000 - 150, z * 10);

    let xRot = -0.6 + Math.sin(time.getElapsedTime() * 0.3) / 10;
    let yRot = 0.8 + Math.sin(time.getElapsedTime() * 0.5) / 10;
    group.current.rotation.set(xRot, yRot, 0);
  })

  return (
    <group ref={group} {...props} dispose={null} scale={[0.008, 0.008, 0.008]}>
      <group position={[0, -40, 0]}>
        <PresentationControls
          global={false} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={false} // Snap-back to center (can also be a spring config)
          speed={2.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[0, 0, 0]} // Default rotation
          polar={[-Infinity, Infinity]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        >
          <group ref={presGroup}>
            <group position={[-339.72, 230.59, -16.34]} rotation={[-0.43, 1.34, 2.27]}>
              <mesh geometry={nodes.Tappo_1__0.geometry} material={materials.RootNode} />
            </group>
            <group position={[97.01, 422.88, 0]} rotation={[-Math.PI / 2, 1.5, -3.02]}>
              <mesh geometry={nodes.Tappo_2__0.geometry} material={materials.RootNode} />
            </group>
            <mesh geometry={nodes.Sweep_NURBS_Nuovo_0.geometry} material={materials.Nuovo} />
            <group position={[0, 425.82, 0]} rotation={[0, -Math.PI / 2, 0]}>
              <mesh geometry={nodes.Spina_Testa_Cavo_0.geometry} material={materials.Testa_Cavo} />
            </group>
            <group position={[0, 163.93, 7.96]}>
              <mesh geometry={nodes.Telecomando_Controller_0.geometry} material={materials.Controller} />
            </group>
          </group>
        </PresentationControls>
      </group>
    </group>
  )
}

useGLTF.preload('/controller/scene.gltf')
