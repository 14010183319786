import React from 'react'
import ChangeableCan from '../other/3D/objects/changeable-can';
import BeerIconBox from '../../components/beer-icon-box/beer-icon-box';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import EpicLinkButton from '../../components/epic-button/epic-link-button';

const mainBeerList = [
    {
        name: "Default",
        label: "/canlabels/defaultsmall.jpg",
        icon: "/skinicons/default.jpg",
    },
    {
        name: "Alternative",
        label: "/canlabels/summer.jpg",
        icon: "/skinicons/alt.jpg",
    },
];

const docImages = [
    process.env.PUBLIC_URL + '/youtube/google-docs-neg.png',
    process.env.PUBLIC_URL + '/youtube/empty.png',
    process.env.PUBLIC_URL + '/youtube/empty.png',
];

const beerChanger = {
    changePreview: (beer) => {
        console.log("changePreview needs to be set in sub component");
    }
}

const contents = [
    {
        name: "GLHF",
        icon: "spaceinvader",
        element: <>
            <Infobox title="Skin Preview" text={
                <>
                    <p>Drag and drop an image onto this window to reskin the can</p>
                    <p>Here are some different mockup skins created throughout the process:</p>
                    <BeerIconBox beerList={mainBeerList} beerChanger={beerChanger}/>
                    <p>Here's a Photoshop template to create your own:</p>
                    <EpicLinkButton images={docImages} theme="1" text="Photoshop template" file="/canlabels/can_label_template.psd"/>
                </>
                
                }/>
        </>,
        element3d: <ChangeableCan beerChanger={beerChanger}/>
    },
];



const Preview = () => {
  return (
    <Slide contents={contents} theme="new3"/> 
  )
}

export default Preview
