import React, { useEffect, useState } from 'react';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import Trailer from '../other/3D/youtube/premade/trailer';
import GatekeeperForm from './gatekeeper-form';
import ModalVideo from 'react-modal-video';
import { Portal } from 'react-portal';
import ReactGA from "react-ga4";
import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';
import EpicButtonSimple from '../../components/epic-button/epic-button-simple';
import './gatekeeper.scss';

const theme = "new3";

function Gatekeeper() {

    const [isOpen, setOpen] = useState(false)

    function videoClick() {
        ReactGA.event({
            category: "Gatekeeper Info Box",
            action: "Video Click",
            label: "Crowdfunding Trailer", // optional
            pageUrl: window.location.href,
            pageTitle: document.title
          });
        
          setOpen(true);
    }

    const trailerImages = [
        process.env.PUBLIC_URL + '/youtube/coolperson.png',
        process.env.PUBLIC_URL + '/youtube/philipvformation.png',
        process.env.PUBLIC_URL + '/youtube/german.png',
    ];
    
    const messageImages = [
        process.env.PUBLIC_URL + '/youtube/iconinvaderblack.png',
        process.env.PUBLIC_URL + '/youtube/pettermessage.png',
        process.env.PUBLIC_URL + '/youtube/iconbeerblack.png',
    ];

    function mainAction() {
        window.location.href = "/";
    }

    const contents = [
        {
            name: "GLHF",
            icon: "spaceinvader",
            element: <>
                <Infobox title="Sign up!" text={
                    <>
                        <EpicYoutubeButton images={messageImages} theme="2" text="Short Summary" youtubeId="7DRDbykbL6s"/>
                        <EpicYoutubeButton images={trailerImages} theme="1" text="Preview Trailer!" youtubeId="angbWa4bslU"/>
                        <div style={{width:"100%", float:"left", marginRight:"6%"}}>
                            <EpicButtonSimple action={mainAction} theme="3" text="To Main Page" />
                        </div>
                        <hr/>
                        <button type="button" className="collapsible">More Info</button>
                        <div className="content">
                            <p>We are looking to collaborate with content creators and social media personalities for our upcoming <b>crowdfunding campaign.</b></p>
                            <p>Your job will be to tell people that we exist, and you will of course be compensated for your effort. Right now we are working on a reward setup for all our collaborators, while gathering interest from big and small creators.</p>
                            <p>Please <b>tell us more about you</b> in the form below, and we'll get back to you with more info when the time is right. Our people will contact your people!</p>
                        </div>
                        <GatekeeperForm/>
                    </>}/>
            </>,
            element3d: <Trailer/>
        }];

        useEffect(() => {
            var coll = document.getElementsByClassName("collapsible");
            var i;
            
            for (i = 0; i < coll.length; i++) {
              coll[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var content = this.nextElementSibling;
                if (content.style.maxHeight){
                  content.style.maxHeight = null;
                } else {
                  content.style.maxHeight = content.scrollHeight + "px";
                } 
              });
            }
        }, [])

    return (        
        <Slide single={true} contents={contents} theme={theme}/>
    );
  }
  
export default Gatekeeper;
