import React, { PureComponent, useState } from 'react'
import ModalVideo from 'react-modal-video'
import './epic-button.scss';
import ReactGA from "react-ga4";
import themeCSS from './epic-button-themes';
import ImageComponent from '../image-component/image-component';


const EpicYoutubeButton = (props) => {

    const [isOpen, setOpen] = useState(false)

    function videoClick() {
        ReactGA.event({
            category: "Video",
            action: "Video Click",
            label: props.text, // optional
            pageUrl: window.location.href,
            pageTitle: document.title
          });
        
          setOpen(true);
    }

    var backgroundTheme = themeCSS(props.theme);

    return (
        <React.Fragment>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={props.youtubeId} onClose={() => setOpen(false)} />
            <div className="epic-button" style={{background: backgroundTheme, backgroundSize: "800%"}} onClick={()=> videoClick()}>
                    <div className="image-container">
                        <ImageComponent className="picture1" src={props.images[0]}/>
                        <ImageComponent className="picture2" src={props.images[1]}/>
                        <ImageComponent className="picture3" src={props.images[2]}/>
                    </div>
                <ImageComponent className="play-button gray" src="https://upload.wikimedia.org/wikipedia/commons/c/c9/YouTube_play_buttom_dark_icon_%282013-2017%29.svg"/>
                <ImageComponent className="play-button red" src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_play_button_icon_%282013%E2%80%932017%29.svg"/>
                <div className="text">{props.text}</div>
            </div>
        </React.Fragment>
    )
}

export default EpicYoutubeButton