/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: GremorySaiyan (https://sketchfab.com/GremorySaiyan)
license: SKETCHFAB Standard (https://sketchfab.com/licenses)
source: https://sketchfab.com/3d-models/owl-animation-fly-2d1d4cdd118b4f929c61b13a05909bd4
title: Owl Animation Fly
*/

import React, { useRef } from 'react'
import { useLoader, useFrame, useThree } from '@react-three/fiber';
import { PresentationControls } from '@react-three/drei';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Clock, FrontSide } from 'three'
import { Vector3 } from 'three';

const time = new Clock();

const Model = props => {
  const model = useLoader(
    GLTFLoader,
    'treasure_map/scene.gltf'
)

    // Here's the animation part
    // ************************* 
    // let mixer
    // if (model.animations.length) {
    //     mixer = new THREE.AnimationMixer(model.scene);
    //     model.animations.forEach(clip => {
    //         const action = mixer.clipAction(clip)
    //         action.play();
    //     });
    // }
    const { camera } = useThree();
    const group = useRef();
    const presGroup = useRef();

    const material = model.materials[Object.keys(model.materials)[0]];
    material.transparent = true;
    material.opacity = 0;

    const scenePos = new Vector3(
      props.position[0],
      props.position[1],
      props.position[2],
    )

    useFrame((state, delta) => {
  
      if (props.introAnimation.done == false) return;

      const mesh1Position = camera.position;
      const mesh2Position = scenePos;
      const distance = mesh1Position.distanceTo(mesh2Position);

      var o = 0;
      if (distance < props.fadeStart) o = 1; 
      if (distance > props.fadeEnd) o = 0;
      if (distance > props.fadeStart && distance < props.fadeEnd) o = 1 - (distance - props.fadeStart) / (props.fadeEnd - props.fadeStart);
  
      material.opacity = o;

      if (o == 0) return;

      let x = Math.sin(time.getElapsedTime() * 0.7 - 0.2) / 4 + Math.sin(time.getElapsedTime() * 0.6 - 0.2) / 4;
      let y = Math.sin(time.getElapsedTime() * 0.25) / 20;
      let z = Math.sin(time.getElapsedTime() * 0.7 - 0.2) / 3;
  
      presGroup.current.position.set(x / 2, y / 2, z / 2);
  
      let xRot = 0.8 + Math.sin(time.getElapsedTime() * 0.3) / 10;
      let yRot = 0 + Math.sin(time.getElapsedTime() * 0.5) / 10;
      presGroup.current.rotation.set(xRot, yRot, 0);

      // mixer?.update(delta / 4)

      // parch.current.position.set(
      //   props.position[0] - 1.1 + Math.sin((action.time - 0.1) * 11) / 37,
      //   props.position[1] - 1.1 + Math.sin((action.time - 0.15) * 11) / 7,
      //   props.position[2] - 1.3

      // ) 
    })
    // *************************

    model.scene.traverse(child => {
        if (child.isMesh) {
            child.castShadow = true
            child.receiveShadow = true
            child.material.side = FrontSide
        }
    })

    return (
      <group ref={group} position={props.position}>
      <PresentationControls
      global={false} // Spin globally or by dragging the model
      cursor={true} // Whether to toggle cursor style on drag
      snap={false} // Snap-back to center (can also be a spring config)
      speed={2.5} // Speed factor
      zoom={1} // Zoom factor when half the polar-max is reached
      rotation={[0, 0, 0]} // Default rotation
      polar={[-Infinity, Infinity]} // Vertical limits
      azimuth={[-Infinity, Infinity]} // Horizontal limits
      config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
    >
      <group ref={presGroup} scale={[1.7, 1.7, 1.7]}>
        <primitive 
            object={model.scene}
            scale={[0.006, 0.006, 0.006]}
            rotation={[0.0, 0, 0]}
            position={[1.5, 0, -3]}
        />
      </group>
      </PresentationControls>
      </group>
    )
}

export default Model;
