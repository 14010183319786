import React from 'react';
import { createRoot } from 'react-dom/client';
//import './index.css';
import './global-styles.scss';
import './button-style.scss';
// import './fonts/F37Neuro-SemiBold.ttf'; 
// import './fonts/F37Neuro-Regular.ttf'; 
import reportWebVitals from './reportWebVitals';
import PageRouting from './contents/pagerouting';
import { BrowserRouter as Router } from 'react-router-dom';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga4";
import PrivacyLink from './components/privacy-link/privacy-link';


if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  console.log("not initializing G4A");
}
else
{
  ReactGA.initialize("G-8WHSLT6D8K");
  ReactGA.send("pageview");  
}


const facebookPixel = "422249043129358";
const facebookPixelBackup = "389517959834347";
const tiktokPixel = "CATFJGRC77U7KHVMIL3G";

//<!-- TikTok-pixel. -->
(function (w, d, t) {
  w.TiktokAnalyticsObject=t;
  var ttq=w[t]=w[t]||[];
  ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"];
  ttq.setAndDefer= function(t,e){
    t[e]=function(){
      t.push([e].concat(Array.prototype.slice.call(arguments,0)))}
    };
  for(var i=0;i<ttq.methods.length;i++)
    ttq.setAndDefer(ttq,ttq.methods[i]);
  ttq.instance=function(t){
    for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)
      ttq.setAndDefer(e,ttq.methods[n]);
    return e
  };
  ttq.load=function(e,n){
    var i="https://analytics.tiktok.com/i18n/pixel/events.js";
    ttq._i=ttq._i||{};
    ttq._i[e]=[];
    ttq._i[e]._u=i;
    ttq._t=ttq._t||{};
    ttq._t[e]=+new Date;
    ttq._o=ttq._o||{};
    ttq._o[e]=n||{};
    n=document.createElement("script");
    n.type="text/javascript";
    n.async=!0;
    n.src=i+"?sdkid="+e+"&lib="+t;
    e=document.getElementsByTagName("script")[0];
    e.parentNode.insertBefore(n,e)
  };
  ttq.load(tiktokPixel);
  ttq.page();
})(window, document, 'ttq');

//<!-- Facebook Pixel Code -->
(function(f,b,e,v,n,t,s) {
  if(f.fbq) return;
  n=f.fbq=function(){
    n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)
  };
if(!f._fbq) f._fbq=n;
n.push=n;
n.loaded=!0;
n.version='2.0';
n.queue=[];
t=b.createElement(e);
t.async=!0;
t.src=v;
s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)
})(window,document,'script', 'https://connect.facebook.net/en_US/fbevents.js');



// <!-- Pixel global functions -->
window.trackRegistration = () => {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    console.log("trackRegistration");
    return;
  }

  if (getCookieConsentValue("GLHFdunkedCookieTwoPointZero") !== "true") return;

  window.ttq.track('CompleteRegistration');
  window.fbq('track', 'CompleteRegistration');
}

window.trackViewContent = () => {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    console.log("trackViewContent");
    return;
  }

  if (getCookieConsentValue("GLHFdunkedCookieTwoPointZero") !== "true") return;

  window.ttq.track('ViewContent');
  window.fbq('track', 'ViewContent');
}

window.trackSubscribe = () => {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    console.log("trackSubscribe");
    return;
  }

  if (getCookieConsentValue("GLHFdunkedCookieTwoPointZero") !== "true") return;

  window.ttq.track('Subscribe');
  window.fbq('track', 'Subscribe');
}

// React basic setup
const container = document.getElementById('root');
const root = createRoot(container);

// See if we want to hide the cookies-popup
const policyUrls = ["/policies", "/cookies", "/privacy"]
const showCookies = policyUrls.includes(window.location.pathname) ? false : true;

function accept() {
  trackPageView();

  ReactGA.event({
    category: "Cookies",
    action: "Cookie Decision Click",
    label: "Accepted", // optional
    pageUrl: window.location.href,
    pageTitle: document.title
  });
}

function decline() {
  ReactGA.event({
    category: "Cookies",
    action: "Cookie Decision Click",
    label: "Declined", // optional
    pageUrl: window.location.href,
    pageTitle: document.title
  });

}

root.render(
  <React.Fragment>
    <CookieConsent
      location="none"
      cookieName="GLHFdunkedCookieTwoPointZero"
      buttonText="That's Cool!"
      declineButtonText="Not cool..."
      enableDeclineButton
      flipButtons
      visible={showCookies === true ? "byCookieValue" : "hidden"}
      // hideOnAccept
      buttonWrapperClasses='consent-button-wrapper'
      contentClasses='consent-content'
      buttonClasses='glhf-button glow'
      declineButtonClasses='glhf-button boooring'
      cookieValue={true}
      declineCookieValue={false}
      disableButtonStyles
      disableStyles
      expires={150}
      onAccept={() => {accept();}}
      onDecline={() => {decline();}}
    >
      <div>
        <h2>Cookie? Anyone?</h2>
        <p>We're making this all better with cookies. <br/><a href="/policies"><span className="clickable-link">Click here</span></a> to read up on exactly what that means.</p>
      </div>
    </CookieConsent>
    
    <PrivacyLink/>
    <Router>
      <PageRouting />
    </Router>
    </React.Fragment>
);

function trackPageView() {
  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")
  {
    console.log("tracked pageview");
    return;
  }
  
  window.fbq('init', facebookPixel); 
  window.fbq('init', facebookPixelBackup);
  window.fbq('track', 'PageView');
}

if (getCookieConsentValue("GLHFdunkedCookieTwoPointZero") === "true")
  trackPageView();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


