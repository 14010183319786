/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef} from 'react'
import { MeshPhysicalMaterial, MeshBasicMaterial, MeshStandardMaterial, TextureLoader, sRGBEncoding, DoubleSide } from 'three';
import { useFrame, useLoader } from '@react-three/fiber'
import { Text } from '@react-three/drei'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import Menu3D from './3D-menu';
import ReactGA from "react-ga4";

var materials = [];

const materialPhone = new MeshPhysicalMaterial({
  clearcoat: 0.5,
  clearcoatRoughness: 0.4,
  metalness: 0.3,
  roughness: 0.7,
  color:"#9A9A9A",
  transparent: true,
  opacity: 0
})
materials.push({material: materialPhone, opacity: 1});

const materialCams = new MeshPhysicalMaterial({
  clearcoat: 0.1,
  clearcoatRoughness: 0.4,
  metalness: 0.1,
  roughness: 0.8,
  color:"#000000",
  transparent: true,
  opacity: 0
})
materials.push({material: materialCams, opacity: 1});

const materialMic = new MeshPhysicalMaterial({
  metalness: 0,
  roughness: 1,
  color:"#020202",
  transparent: true,
  opacity: 0
})
materials.push({material: materialMic, opacity: 1});

const materialScreen2 = new MeshPhysicalMaterial({
  color:"white",
  opacity: 0,
  transparent: true,
 metalness: 0.1,
 roughness: 0.1,
 envMapIntensity: 20,
  specularIntensity: 2,
  specularColor:"white",
  ior: 1.5,
  transparent: true,
});
materials.push({material: materialScreen2, opacity: 0.15});

const UIMaterial = new MeshBasicMaterial({
  transparent: true,
  opacity: 0,
  side: DoubleSide,
  depthTest: false,
  depthWrite: false
});

materials.push({material: UIMaterial, opacity: 1});

const materialBattery = UIMaterial.clone();
materials.push({material: materialBattery, opacity: 1});

var today = new Date();
var time = (today.getHours() + 100).toString().substring(1,3) + ":" + (today.getMinutes() + 100).toString().substring(1,3); 

const canTexture1 = new TextureLoader().load( "/canlabels/default.jpg" );
canTexture1.flipY = false;
canTexture1.encoding = sRGBEncoding;
const canTexture2 = new TextureLoader().load( "/canlabels/summer.jpg" );
canTexture2.flipY = false;
canTexture2.encoding = sRGBEncoding;
const canTexture3 = new TextureLoader().load( "/canlabels/NES.jpg" );
canTexture3.flipY = false;
canTexture3.encoding = sRGBEncoding;
const canTexture4 = new TextureLoader().load( "/canlabels/c64.jpg" );
canTexture4.flipY = false;
canTexture4.encoding = sRGBEncoding;

const batteryMap = new TextureLoader().load( "/battery.jpg" );
batteryMap.flipY = true;

//const animeTexture = new CanvasTexture(getAnimeCanvas());

materialBattery.alphaMap = batteryMap;

const loader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
loader.setDRACOLoader( dracoLoader );

var camera1, camera2, microphone, screen, phone;

const iconTexture2 = new TextureLoader().load( "/skinicons/summer.png" );
iconTexture2.encoding = sRGBEncoding;
const iconTexture3 = new TextureLoader().load( "/skinicons/nes.png" );
iconTexture3.encoding = sRGBEncoding;
const iconTexture4 = new TextureLoader().load( "/skinicons/c64.png" );
iconTexture4.encoding = sRGBEncoding;
const iconTexture5 = new TextureLoader().load( "/skinicons/anime.png" );
iconTexture5.encoding = sRGBEncoding;

const UIMaterialButton = new MeshBasicMaterial({
  //blending: AdditiveBlending,
  map: iconTexture2,
  transparent: true,
  // side: DoubleSide,
  // depthWrite: false,
  // depthTest: false,
  opacity: 0
});

const iconMat2 = UIMaterialButton.clone();
iconMat2.map = iconTexture2;
const iconMat3 = UIMaterialButton.clone();
iconMat3.map = iconTexture3;
const iconMat4 = UIMaterialButton.clone();
iconMat4.map = iconTexture4;
const iconMat5 = UIMaterialButton.clone();
iconMat5.map = iconTexture5;

const runesTexture = new TextureLoader().load( "/canlabels/anime/runes.png" );
runesTexture.encoding = sRGBEncoding;

const runesMat = new MeshStandardMaterial( {
  side:DoubleSide,
  map: runesTexture,
  transparent: true,
  color: "#1cd2ba"
})

materials.push({material: runesMat, opacity: 0.7});


export default function Model({ ...props }) {
  const group = useRef()

  //const gltf = loader.load('/hollowphonescreen3.gltf')
  const gltf = useLoader(
    GLTFLoader,
    'hollowphonescreen3.gltf'
  )
  phone = gltf.scene.children[0].children[0].children[0];
  screen = gltf.scene.children[1];
  camera1 = gltf.scene.children[2];
  camera2 = gltf.scene.children[3];
  microphone = gltf.scene.children[4];

  function GAEvent(name) {
    ReactGA.event({
      category: "Skins",
      action: "Skin Change Click",
      label: name, // optional
      pageUrl: window.location.href,
      pageTitle: document.title
    });
  }

  const skinIcons = [
    {
      name: "Summer",
      material: iconMat2,
      onClick: () => { 
        props.setCanTexture(canTexture2);
        GAEvent("Summer");
      }
    },
    {
      name: "NES",
      material: iconMat3,
      onClick: () => { 
        props.setCanTexture(canTexture3); 
        GAEvent("NES");
      }
    },
    {
      name: "c64",
      material: iconMat4,
      onClick: () => { 
        props.setCanTexture(canTexture4); 
        GAEvent("c64");
      }
    },
  ];

  // Anime skin section

  // const animeRing = useRef()
  // var animeActive = false;
  // const [animeVideoElement] = useState(() => {
  //     const vid = document.createElement('video');
  //     vid.src = animeVid;
  //     vid.loop = true;
  //     vid.muted = true;
  //     vid.play();

  //     return vid;
  // })

  // var animeTexture = null;
  // useEffect(() => {

  //   animeTexture = new VideoTexture(animeVideoElement);
  //   animeTexture.flipY = false;
  //   animeTexture.encoding = sRGBEncoding;
    
  //   skinIcons.push(
  //     {
  //       name: "anime",
  //       material: iconMat5,
  //       canTexture: animeTexture,
  //       setState: null,
  //       onOpen: () => animeActive = true,
  //       onClose: () => animeActive = false,
  //     }
  //   )

  // }, [animeVideoElement])


  useFrame(() => {
    if (props.introAnimation.done == false) return;

    materials.forEach(element => element.material.opacity = element.opacity * props.opacity.value);
    if (props.opacity.value == 0) return;

    if (props.stencils.added != true) {
      Object.assign(runesMat, props.stencils.stencil);
      props.stencils.added = true;
    }


    // if (animeActive == false) 
    // {
    //   animeRing.current.material.opacity = 0;
    //   return;
    // }

    // const ringOpacity = Math.sin(clock.elapsedTime * 0.5) * Math.sin(clock.elapsedTime * 0.7) * Math.sin(clock.elapsedTime * 0.2);
    // animeRing.current.material.opacity = ringOpacity * 0.6;

    // animeRing.current.rotation.set(0.15, clock.getElapsedTime() / 20, 0);
  });

  return (
    <group ref={group} {...props} dispose={null} position={props.position} rotation={[-0.1, 0.5, 0]} scale={[0.035, 0.035, 0.035]}>
      <group position={[-9, 8, 71]}>

        <group position={[0, 0.0, -13.4]}>
          <mesh geometry={camera1.geometry} material={materialCams} position={[-30.71, 86.57, 13.65]} rotation={[1.57, 0, 0]} scale={[2.01, 2.01, 2.01]} />
          <mesh geometry={camera2.geometry} material={materialCams} position={[-18.72, 86.61, 13.99]} rotation={[1.57, 0, 0]} scale={[1.01, 1.01, 1.01]} />
          <mesh geometry={microphone.geometry} material={materialMic} position={[-9.65, 86.63, 14.31]} rotation={[1.57, 0, 0]} scale={[1.01, 1.01, 1.01]} />
        </group>

        <mesh geometry={screen.geometry} material={materialScreen2} position={[0, -0.26, -0.07]} scale={[46.32, 81.89, 0.01]} />

        <group position={[0, 0.18, -9.99]} rotation={[-1.61, 0.04, -0.41]}>
          <mesh geometry={phone.geometry} material={materialPhone} position={[4.28, 0, -104.57]} rotation={[0.06, -0.02, 0.41]} />
        </group>

        <Menu3D menuIcons={skinIcons} opacity={props.opacity} baseScale={[1, 1, 0.01]} basePosition={[0, -70, 0]}/>
        {/* Anime skin ring */}
        {/* <mesh ref={animeRing} geometry={cylinderGeo} material={runesMat} position={[8, 20, -60]} rotation={[0.0, -0.0, 0.0]} /> */}

        <Text material={UIMaterial} position={[-36, 76, 0.1]} scale={[3.5, 3.5, 3.5]} anchorX="center" anchorY="middle">{time}</Text>
        <mesh position={[40, 76, 0.1]} scale={[0.4, 0.4, 0.4]} material={materialBattery}>
          <planeBufferGeometry attach="geometry" args={[10, 10]} />
        </mesh>
      </group>
    </group>
  )
}