import React from 'react';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import CanSkins from '../other/3D/objects/can-skins';
import './new-main.scss';
import Trailer from '../other/3D/youtube/premade/trailer';
import Bond from '../other/3D/youtube/premade/bond';
import Interview from '../other/3D/youtube/premade/interview';
import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';
import Jeff from '../other/3D/youtube/premade/jeff';
import Cocacentered from '../other/3D/objects/cocacentered';


const theme = "new3";

const trailerImages = [
    process.env.PUBLIC_URL + '/youtube/coolperson.png',
    process.env.PUBLIC_URL + '/youtube/philipvformation.png',
    process.env.PUBLIC_URL + '/youtube/german.png',
];

const bondImages = [
    process.env.PUBLIC_URL + './youtube/toplane.png',
    process.env.PUBLIC_URL + './youtube/philipbond.png',
    process.env.PUBLIC_URL + './youtube/elrond.png',
];

const newsImages = [
    process.env.PUBLIC_URL + './youtube/iconspaceshipblack.png',
    process.env.PUBLIC_URL + './youtube/philnews.png',
    process.env.PUBLIC_URL + './youtube/empty.png',
];

const fortniteImages = [
    process.env.PUBLIC_URL + '/youtube/interviewpetter.png',
    process.env.PUBLIC_URL + '/youtube/iconinvader.png',
    process.env.PUBLIC_URL + '/youtube/interviewolof.png',
];

const producerQuitsImages = [
    process.env.PUBLIC_URL + '/youtube/ludvigchair.png',
    process.env.PUBLIC_URL + '/youtube/johandespair.png',
    process.env.PUBLIC_URL + '/youtube/beerbottle.png',
];

const bottleImages = [
    process.env.PUBLIC_URL + '/youtube/bottlenes2.png',
    process.env.PUBLIC_URL + '/youtube/bottleanimation2.png',
    process.env.PUBLIC_URL + '/youtube/bottlejap2.png',
];

const wrapImages = [
    process.env.PUBLIC_URL + '/youtube/philipcamera.png',
    process.env.PUBLIC_URL + '/youtube/johanpetterhug.png',
    process.env.PUBLIC_URL + '/youtube/german2.png',
];

const JeffImages = [
    process.env.PUBLIC_URL + '/youtube/jeffsign.png',
    process.env.PUBLIC_URL + '/youtube/jeff.png',
    process.env.PUBLIC_URL + '/youtube/johansceptic.png',
];


const contents = [
    {
        name: "GLHF",
        icon: "sword",
        element: <Infobox title="GLHF" text={
        <>
            <p><b>There's no beer for gamers?!</b><br/><br/> There are almost 4 billion gamers in the world (almost 2 billion if only count PCs and consoles). We're creating the first global beverage brand for these people.<br/><br/></p>
            <EpicYoutubeButton images={trailerImages} theme="1" text="Epic Trailer!" youtubeId="angbWa4bslU"/>
        </>
            }/>,
        element3d: <Cocacentered/>
    },
    {
        name: "Beer 2.0",
        icon: "beer",
        element: <Infobox title="Beer 2.0" text={
        <>
            <EpicYoutubeButton images={bottleImages} theme="2" text="AR Beer" youtubeId="66qvP15Nm_4"/>
            <p>We're adding a whole new dimension to the drinking experience. Scan your beer to get a loot box. Find skins for your beer, avatars, gain experience and play against friends through our app.</p>
       </>}/>,
        element3d: <CanSkins/>
    },
    {
        name: "Segmentsfilm",
        icon: "spaceship",
        element: <Infobox title="For Gamers By Gamers" text={
        <>
            <p>We're not just a brand. We're creating a community running gaming channels, chat servers and ingame guild. We're sponsoring a grass roots gaming event for each beer you drink. You choose which one!</p>
            <br/>
            <EpicYoutubeButton images={bondImages} theme="1" text="Board Gamers" youtubeId="zn5LWdKlGTU"/>
            <EpicYoutubeButton images={wrapImages} theme="3" text="Almost there!" youtubeId="KtDyK2g4I04"/>
        </>}/>,
        element3d: <Bond/>
    },
];

function Deck() {

    return (
        <Slide contents={contents} theme={theme}/>
    );
  }
  
export default Deck;
