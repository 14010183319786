import React from 'react';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import Cookie from '../other/3D/objects/cookie';

const theme = 'new3';

var randomcat = "https://cataas.com/cat/says/Random%20Internet%20Cat?" + Date.now();

const contents = [
    {
        name: "GLHF",
        icon: "spaceinvader",
        element: <>
            <Infobox title="GLHF" text={
                <>
                    <p>Oh, savvy explorers, are we? On a hunt for easter eggs, are we? Well, whether you are or not, you found one. But it's not done. Come back after the launch. Here's a random cat meanwhile.</p>
                    <img src={randomcat} style={{width:"100%"}}></img>
                </>
                }/>
        </>,
        element3d: <Cookie/>
    },
]

function Easter(props) {  


    return (
        <Slide contents={contents}
        theme="new3"/> 
    );
  };
  
export default Easter;
