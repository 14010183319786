import { useRef, useEffect, useState } from 'react'
import { RepeatWrapping, Clock, MeshStandardMaterial, TextureLoader, sRGBEncoding, MeshBasicMaterial, MeshPhysicalMaterial } from 'three'
import { useFrame } from '@react-three/fiber';
import Menu3D from './objects/3D-menu';
import Cocacentered from './objects/cocacentered';
import BlastBarrell from './objects/ar-skins/blast-barrell';
import { useTexture, useGLTF, Text } from '@react-three/drei'
import { useSpring } from '@react-spring/three';

const previewTexture = new TextureLoader().load( "/beermoodboard/test.jpg" );
previewTexture.wrapS = RepeatWrapping;

const infoTexture = new TextureLoader().load( "/beermoodboard/test.jpg" );

    const conceptTextureSkin = new TextureLoader().load( "/iconz/ui/concepticon.png" );
    conceptTextureSkin.encoding = sRGBEncoding;
    const iconTextureSkin = new TextureLoader().load( "/iconz/ui/skinicon.png" );
    iconTextureSkin.encoding = sRGBEncoding;
    const iconTextureCan = new TextureLoader().load( "/iconz/ui/canicon.png" );
    iconTextureCan.encoding = sRGBEncoding;
    const iconTextureInfo = new TextureLoader().load( "/iconz/ui/infoicon.png" );
    iconTextureInfo.encoding = sRGBEncoding;
    
  
  const UIMaterialButton = new MeshBasicMaterial({
    transparent: true,
    opacity: 1
  });
  
  const iconMatMoodboard = UIMaterialButton.clone();
  iconMatMoodboard.map = conceptTextureSkin;
  const iconMatCan = UIMaterialButton.clone();
  iconMatCan.map = iconTextureCan;
  const iconMatSkin = UIMaterialButton.clone();
  iconMatSkin.map = iconTextureSkin;
  const iconMatInfo = UIMaterialButton.clone();
  iconMatInfo.map = iconTextureInfo;

const time = new Clock();

const CraftPreviewer = ({ beerChanger, ...props }) => {

    const { nodes } = useGLTF('/arskins/tv3.gltf');
    const moodboardRef = useRef();
    const infoRef = useRef();

    //const stencils = { stencil: null, stencilInv: null, added: false }

    const boxMat = new MeshStandardMaterial(
      {
          color: "#FFFFFF",
          map: previewTexture,
      }
    );

    const menuMat = new MeshStandardMaterial(
      {
          color: "#272727",
      }
    );

    const infoMat = new MeshStandardMaterial(
      {
          color: "#FFFFFF",
          map: infoTexture,
      }
    )

    const textMat = new MeshStandardMaterial(
      {
          color: "#FFFFFF",
          transparent: true
      }
    )

    const [{shake}, shakeAPI]  = useSpring(() => ({ shake: 0.5 }))

    function animateShake() {
        shakeAPI.start({ 
            to: [{ shake: 1 }, { shake: 0.3 }, { shake: 0.5}],
            config: { duration: 40 }
        });
    }

    let selectedObject = "can";
    
    const objectPositions = {
        moodboard: [0, 0, 0],
        can: [0, 0, 0],
        skin: [0, 0, 0],
        info: [0, 0, 0],
    }

    function setPositions()
    {
        const shakeOffset = shake.get() * 0.1;

        objectPositions.moodboard = [0.23, 0.54 + (selectedObject === "moodboard" ? 0 : 20), -2];
        //objectPositions.info =      [0.23, 0.54 + (selectedObject === "info" ? 0 : 20), 0];
        objectPositions.can =       [0.0 + shakeOffset * 0.5, 0 + (selectedObject === "can" ? 0 : 20) + shakeOffset, 0];
        objectPositions.skin =      [-0.13 + shakeOffset * 0.6, 0.0 + (selectedObject === "skin" ? 0 : 20) + shakeOffset, 0.7];
    }

    const previewIcons = [
        {
          name: "Can",
          material: iconMatCan,
          onClick: () => {
            selectedObject = "can";
            animateShake();
          }
        },
        {
            name: "AR Skin",
            material: iconMatSkin,
            onClick: () => {
              selectedObject = "skin";
              animateShake();          
            }
        },
        {
          name: "Moodboard",
          material: iconMatMoodboard,
          onClick: () => {
            selectedObject = "moodboard";
            animateShake();
          }
        },

    ];

      const canTexture = useTexture("/canlabels/defaultsmall.jpg", (texture) => {
        //texture.encoding = sRGBEncoding;
        texture.flipY = false
      });

      const canOverride = {
        map: canTexture,
        position: [0, 0, 0],
        opacity: 1,
        changePreview: (beer) => {
            console.log("Needs to be set in cocacentered.js");
        }
      }

      const skinOpacityContainer =
      {
        value: 0
      }

    useEffect(() => {
        
        beerChanger.changePreview = (beer) => {

            console.log("beer changed" + beer.name);

            canOverride.changePreview(beer);

            const newPreviewTexture = new TextureLoader().load( beer.moodboard );
            newPreviewTexture.encoding = sRGBEncoding;
            newPreviewTexture.wrapS = RepeatWrapping;
            newPreviewTexture.repeat.set(1/3, 1);
            boxMat.map = newPreviewTexture;

            const newInfoTexture = new TextureLoader().load( beer.infoImage );
            newPreviewTexture.encoding = sRGBEncoding;
            infoMat.map = newInfoTexture;

            canTexture.image.src = beer.canlabel;
            canTexture.needsUpdate = true;

        }
    }, []);

    useFrame(() => {

        setPositions();

            moodboardRef.current.position.x = objectPositions.moodboard[0];
            moodboardRef.current.position.y = objectPositions.moodboard[1];
            moodboardRef.current.position.z = objectPositions.moodboard[2];

            boxMat.map.offset.x = time.getElapsedTime() * 0.017;

            // infoRef.current.position.x = objectPositions.info[0];
            // infoRef.current.position.y = objectPositions.info[1];
            // infoRef.current.position.z = objectPositions.info[2];

            canOverride.position = objectPositions.can;
      });
    
      const { nodes: tvNodes, tvMaterials } = useGLTF('./arskins/tv3-transformed.glb')

      const materialTV = new MeshPhysicalMaterial({
        clearcoat: 0.5,
        clearcoatRoughness: 0.4,
        metalness: 0.3,
        roughness: 0.7,
        color:"#3A3A3A",
      })

      const [beerName, setBeerName] = useState("Default Beer")

  return (
    <group position={props.position} rotation={[-0.1, 0.5, 0]} scale={1.3}>
        <group position={[0, -0.2, 0]}>

        {/* TV */}
        {/* <group scale={4.5} position={[0, -2.1, 0]}>
          <mesh geometry={tvNodes.Text.geometry} material={tvNodes.Text.material} position={[-0.09, 0.21, 0.547]} rotation={[1.566, 0, 0]} />
          <group position={[-0.122, 0.709, 0]}>
            <mesh geometry={tvNodes.Cube_1.geometry} material={materialTV}/> */}
            

            {/* <mesh >
              <primitive object={tvNodes.Cube_2.geometry} attach="geometry"/>
              <MeshPortalMaterial> */}
          
          {/* TV content */}
          <group>
            {/* Mood board box */}
            <mesh ref={moodboardRef} rotation={[0, 0, 0]} material={boxMat}>
                <planeBufferGeometry args={[7.5, 7.5]}/>
            </mesh>

            {/* Preview can */}
            <Cocacentered scale={0.7} override={canOverride} beerChanger={beerChanger}/>

            {/* AR Skin */}
            <BlastBarrell opacity={skinOpacityContainer} objectPositions={objectPositions}/>

            {/* Info box */}
            {/* <mesh ref={infoRef} rotation={[0, 0, 0]} material={infoMat}>
                <planeBufferGeometry args={[6.5, 6.5]}/>
            </mesh> */}
          </group>

              {/* </MeshPortalMaterial>

            </mesh> */}
            
            {/* <mesh geometry={nodes.Cube_2.geometry} material={materials.Screen} /> */}
          {/* </group>
          <mesh geometry={tvNodes.Cylinder.geometry} material={materialTV} position={[-0.286, 0.156, 0.518]} rotation={[Math.PI / 2, 0, 0]} scale={0.8} />
          <mesh geometry={tvNodes.Plane.geometry} material={materialTV} position={[-0.122, 0.509, 0.462]} rotation={[Math.PI / 2, 0, 0]} />
        </group> */}

        {/* Bottom menu */}
          {/* <group position={[0, 0.2, 0]} scale={0.9}>
            <mesh position={[-1.51, -1.35, 2.9]} rotation={[0, 0, 0]} material={menuMat}>
            <planeBufferGeometry args={[2.05, 0.9]}/>
            </mesh>
            <Text material={textMat} anchorX="left" fontSize={0.15} position={[-2.46, -1.66, 3]} rotation={[0, 0, 0]} color="white">
                {beerName}
            </Text>

            <Menu3D menuIcons={previewIcons} opacity={{value: 1}} baseScale={[0.03, 0.03, 0.03]} basePosition={[-1.5, -1.52, 3]}/>
          </group> */}
        
        </group>
    </group>
  )
}

useGLTF.preload('/arskins/tv3.gltf');
export default CraftPreviewer;