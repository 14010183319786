import React, { useEffect } from "react";

const Redirect = (props) => {

    useEffect(() => {
        window.location.href = props.to;
      }, []);

    return (
        <h1 style={{color:"white"}}>THE CAKE IS A LIE</h1>
    );
}

export default Redirect;
