import {MeshBasicMaterial, DoubleSide } from 'three';
import { useFrame } from '@react-three/fiber'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';

const materialUIBox = new MeshBasicMaterial({
    transparent: true,
    opacity: 0,
    side: DoubleSide,
    depthTest: false,
    depthWrite: false
  });

const iconSize = 20;

function Menu3D({ menuIcons, opacity, basePosition, baseScale }) {

    useFrame(() => {
        materialUIBox.opacity = opacity.value;
    })
  
    function click(clickedIcon) {
      menuIcons.forEach((icon) => {    
        if (clickedIcon.name == icon.name) 
        {
          icon.setState(true);
          if (icon.onOpen != null) icon.onOpen();
        }
        else 
        {
          icon.setState(false);
          if (icon.onClose != null) icon.onClose();
        }
      })
      clickedIcon.onClick();
    }
  
    return (
      <group scale={baseScale} position={basePosition}>
        {menuIcons.map((icon, i) => {
            const offsetX = i * (iconSize * 1.1) - (menuIcons.length - 1) * (iconSize * 1.1) / 2;
            const pos = [offsetX, iconSize / 2, 0];
          return <MenuButton key={"menubutton" + i} i={i} icon={icon} clickRef={click} opacity={opacity}
                position={pos}/>
        })}
      </group>
    )
  }
  
  const rBoxGeo = new RoundedBoxGeometry( iconSize, iconSize, 1, iconSize / 2, 1 );
  
  
  function MenuButton(props) {
  
    const materialButton = materialUIBox.clone();
  
    var iconOpacity = 0.5;
    var active = false;
  
    useFrame(() => {
      materialButton.opacity = props.opacity.value * iconOpacity;
      props.icon.material.opacity = props.opacity.value;
    })
  
    props.icon.setState = setActive;
  
    function setActive(value) {
      active = value;
      if (active == true) iconOpacity = 1;
      if (active == false) iconOpacity = 0.5;
    }
  
    function over() {
      if (active == false) iconOpacity = 0.75
    }
    function out() {
      if (active == false) iconOpacity = 0.5   
    }
    function click() {
      props.clickRef(props.icon);
    }
  
    return (
      <group position={props.position} scale={[1, 1, 0.01]}>
          <mesh 
            //geometry={roundedButtonGeo} 
            geometry={rBoxGeo}
            material={materialButton} 
            position={[0, 0, 0]}
            onPointerOver={over} onPointerOut={out} onPointerUp={click} 
            transform occlude
          >
            {/* <boxBufferGeometry attach="geometry" args={[10, 10, 1]} /> */}
          </mesh>
          <mesh material={props.icon.material} 
            position={[0, 0, 0.05]}
            onPointerOver={over} onPointerOut={out}
            transform occlude
          >
            <boxBufferGeometry attach="geometry" args={[iconSize, iconSize, 1]} />
          </mesh>
      </group>
  
    )
  }
  
export default Menu3D;