/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { MeshStandardMaterial, TextureLoader, sRGBEncoding } from 'three';
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF, PresentationControls } from '@react-three/drei'

const texture = new TextureLoader().load( "/canlabels/defaultsmall.jpg" );
texture.encoding = sRGBEncoding;
texture.flipY = false

const materialCan = new MeshStandardMaterial({
  color:"#FFFFFF",
  metalness: 0.6,
  roughness: 0.35,
  envMapIntensity: 0.7,
  map: texture,
  transparent: true,
  opacity: 0
})

export default function Model({ ...props }) {

  const can = useRef();
  const group = useRef();
  const { camera } = useThree();
  
  const { nodes } = useGLTF('/cocacentered.gltf');

  useEffect(() => {
    if (props.override !== undefined)
    {
      materialCan.opacity = props.override.opacity;
      materialCan.map = props.override.map;      

      props.override.changePreview = (beer) => {
        console.log("Changing preview to " + beer.label);
        const newCanTexture = new TextureLoader().load( beer.label );
        newCanTexture.encoding = sRGBEncoding;
        newCanTexture.flipY = false
        materialCan.map = newCanTexture; 
      };
    }
  }, [])


  useFrame(({ clock }) => {

    if (props.override !== undefined)
    {
      materialCan.opacity = props.override.opacity;

      group.current.position.x = props.override.position[0];
      group.current.position.y = props.override.position[1];
      group.current.position.z = props.override.position[2];

      let defaultYRotationOffset = 0;
  
      let x = 0 + Math.sin(clock.getElapsedTime() * 1.4 - 0.2) / 40 + Math.sin(clock.getElapsedTime() * 1.1 - 0.2) / 40;
      let y = 0.87 + Math.sin(clock.getElapsedTime() * 0.5) / 20;
      let z = 0 + Math.sin(clock.getElapsedTime() * 1.4 - 0.2) / 30;
  
      can.current.position.set(x, y, z);
  
      let yRot = -1.9 + Math.sin(clock.getElapsedTime() * 0.5) / 2 + defaultYRotationOffset;
      can.current.rotation.set(0, yRot, 0);

      return;
    }

    if (props.introAnimation.preDone == false) return;

    can.current.rotation.y = -clock.getElapsedTime() / 20 + props.percentage.value / 60;

    const mesh1Position = camera.position;
    const mesh2Position = group.current.position;
    const distance = mesh1Position.distanceTo(mesh2Position);
    
    let opacity = 0;
    if (distance < props.fadeStart) opacity = 1; 
    if (distance > props.fadeEnd) opacity = 0;
    if (distance > props.fadeStart && distance < props.fadeEnd) opacity = 1 - (distance - props.fadeStart) / (props.fadeEnd - props.fadeStart);

    materialCan.opacity = opacity;
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <PresentationControls
          global={false} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={false} // Snap-back to center (can also be a spring config)
          speed={2.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[0, 0, 0]} // Default rotation
          polar={[-Infinity, Infinity]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        >

          <mesh ref={can} geometry={nodes.Cylinder001.geometry} rotation={[0.05, 0, 0.07]} material={materialCan} scale={0.7}/>
          {/* <mesh geometry={textGeo} material={materialText}  position={[0, 0, 1.22]}/> */}
        </PresentationControls>
      </group>
  )
}

useGLTF.preload('/cocacentered.gltf')
