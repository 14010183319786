import React, { useState, useEffect } from "react";

const ImageComponentSimple = ({ className, src }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => setLoaded(true);
  }, [src]);

  return <img className={className + (loaded ? " loaded" : " unloaded")} src={src} alt="" />;
};

export default ImageComponentSimple;
