import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga4";

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const GatekeeperForm = () => {  

    const [submitted, setSubmitted] = useState(false);
    const [buttonText, setButtonText] = useState("Submit");
    const [done, setDone] = useState(false);

    var captcha = false;
    
    function onCaptchaChange(value) {
        console.log(value);
        captcha = value;
    }

    function trackSubmitClick(value) {
        ReactGA.event({
            category: "Gatekeeper",
            action: "Gatekeeper Submit",
            label: value === true ? "Success" : "Failure", // optional
            pageUrl: window.location.href,
            pageTitle: document.title
            });
    }

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {

        if (submitted === true || captcha === false) 
        {
            trackSubmitClick(false);
            return;
        }

        trackSubmitClick(true);
        setSubmitted(true);
        setButtonText("Submitting...");

        fetch(
            'https://v1.nocodeapi.com/dreamish/google_sheets/AcmdQKYAelmXERTI/addRows?tabId=Data', {
                method: 'POST',
                headers: myHeaders,
                redirect: "follow",
                body: JSON.stringify
                    ([{
                    "Email": data.Email,
                    "Link" : data.Link,
                    }])
            }
            ).then((response) => {
                
                setDone(true);
                setButtonText("Thank you!");
                	
                window.trackRegistration();
                
            }).catch((error) => {
                console.log(error);
        });

        console.log(data);
    }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <p><b>Sign up</b></p>
        <div className="input-wrapper">
            <label>Email</label>
            <input className={"form-control" + errors.hasOwnProperty('Email') ? '' : 'is-invalid'} 
                type="text" placeholder="Enter email" 
                disabled={submitted}
                {...register("Email", {required: {value: true, message:"This field is required."}, pattern: {value: /^\S+@\S+$/i, message:"Wrong format, silly!"}})}/>
            <p className="invalid-feedback">{errors.Email?.message}</p>
        </div>
        <div className="input-wrapper">
            <label>Channel Link</label>
            <input type="text" placeholder="Channel link (or name)"
                disabled={submitted}
                {...register("Link")}
            />
        </div>
        <br/><br/>
        <div className="captcha-container">
            <ReCAPTCHA
                sitekey="6LfMQKIgAAAAAGh8OxVQ7QrRhlslZ8ATta9jVLuk"
                onChange={onCaptchaChange}
                size="compact"
            />
        </div>
        <br/>
        <button 
            className= {"submit-button glow-on-hover" + (submitted ? " submitting" : '') + (done ? " done" : '')}  
            type="submit">
                {buttonText}
        </button>
    </form>
  )
}

export default GatekeeperForm