import React from 'react'
import Youtube3D from '../youtube-3D'

const Interview = (props) => {
    const images = [
        './youtube/interviewpetter.png',
        './youtube/interviewgustaf.png',
        './youtube/interviewolof.png',
    ]

    return (
    <Youtube3D 
        images={images}
        name="Founders Interview"
        youtubeId="bStItpN1h_Q"
        {...props}
    />
  )
}

export default Interview