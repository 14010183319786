import React, {useRef, useState, useEffect} from 'react'
import PlayButton from './Playbutton'
import { Clock, MeshStandardMaterial, PlaneBufferGeometry, sRGBEncoding, TextureLoader, Vector3 } from 'three'
import { Html, PresentationControls, Text } from '@react-three/drei'
import ModalVideo from 'react-modal-video';
import { Portal } from 'react-portal';
import { useFrame, useThree } from '@react-three/fiber';
import ReactGA from "react-ga4";

const time = new Clock();

const boxMat = new MeshStandardMaterial(
    {
        color: "#000000",
        transparent: true
    }
);
const textMat = new MeshStandardMaterial(
    {
        color: "#FFFFFF",
        transparent: true
    }
)

const Youtube3D = (props) => {

    const [isOpen, setOpen] = useState(false);

    const innerGroup = useRef();
    const button = useRef();

    var placements = [3, 1, 2];

    function click() {

        ReactGA.event({
            category: "3D Model",
            action: "Video Click",
            label: "Crowdfunding Trailer", // optional,
            pageUrl: window.location.href,
            pageTitle: document.title
          });        
        
        setOpen(true);
    }

    const { camera } = useThree();

    const opacity = {value : 0}

    const scenePos = new Vector3(
        props.position[0],
        props.position[1],
        props.position[2],
      )

    const group = useRef();

    const newBoxMat = boxMat.clone();
    const newTextMat = boxMat.clone();

    useFrame(() => {

        if (props.introAnimation.done == false) return;

        const mesh1Position = camera.position;
        const mesh2Position = scenePos;
        const distance = mesh1Position.distanceTo(mesh2Position);
  
        var o = 0;
        if (distance < props.fadeStart) o = 1; 
        if (distance > props.fadeEnd) o = 0;
        if (distance > props.fadeStart && distance < props.fadeEnd) o = 1 - (distance - props.fadeStart) / (props.fadeEnd - props.fadeStart);
    
        opacity.value = o;
        newBoxMat.opacity = o;
        newTextMat.opacity = o;

        if (o === 0) return;
        
        let x = scenePos.x + Math.sin(time.getElapsedTime() * 0.7 - 0.2) / 4 + Math.sin(time.getElapsedTime() * 0.6 - 0.2) / 4;
        let y = scenePos.y + Math.sin(time.getElapsedTime() * 0.25) / 20;
        let z = scenePos.z + Math.sin(time.getElapsedTime() * 0.7 - 0.2) / 3;
    
        group.current.position.set(x, y, z);
    
        let xRot = 0 + Math.sin(time.getElapsedTime() * 0.3) / 10;
        let yRot = 0 + Math.sin(time.getElapsedTime() * 0.5) / 10;
        group.current.rotation.set(xRot, yRot, 0);
    
    })

    return (
      <group ref={group} position={props.position}>
            <Html>
                <Portal>
                    <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={props.youtubeId} onClose={() => setOpen(false)} />
                </Portal>
            </Html>

            <group ref={innerGroup} rotation={[0.1, 0.7, 0]}>
                {props.images.map((image, i) => {
                                return <TeaserImage 
                                    key={i} 
                                    i={i}
                                    url={image}
                                    rotation={[0, 0, (i * -0.6) - ((props.images.length - 1) * -0.3)]}
                                    position={[(i * 0.8) - ((props.images.length - 1) * 0.4), 0, placements[i] * -0.4 ]}
                                    opacity={opacity}
                                />;
                            })}
                <PlayButton opacity={opacity} onClick={click} />
                <mesh position={[0, -2.7, -0.1]} material={newBoxMat}>
                    <planeBufferGeometry args={[5.3,1]}/>
                </mesh>
                <Text material={newTextMat} fontSize={0.5} position={[0, -2.7, 0.0]} color="white">
                    {props.name}
                </Text>
            </group>
      </group>
  )
}

export default Youtube3D;

const materialCan = new MeshStandardMaterial({
    color:"#FFFFFF",
    roughness: 0.7,
    envMapIntensity: 0.3,
    transparent: true,
    opacity: 1,
    depthWrite: false
  })

const TeaserImage = (props) => {

    const iconTexture = new TextureLoader().load( props.url );
    iconTexture.encoding = sRGBEncoding;

    const material = materialCan.clone();
    material.map = iconTexture;

    const geometry = new PlaneBufferGeometry(4, 4)
    geometry.translate(0, 1.5, 0);

    useFrame(() => {
        material.opacity = props.opacity.value; 
    })

    return (
        <group position={props.position}>
            <mesh  
                rotation={props.rotation}
                material={material} 
                position={[0, 0, 0]} 
                geometry={geometry} />
        </group>
    )
}