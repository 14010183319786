import React from 'react';

const Infobox = (props) => {

    return (
        <div className="info-box">
            <h2>{props.title}</h2>
            {props.text}
        </div>
    )
}

export default Infobox;