import React, { useState, Component } from 'react'
import './epic-button.scss';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import themeCSS from './epic-button-themes';


const EpicGalleryButton = (props) => {

    const [isOpen, setOpen] = useState(false);
    const [photoIndex, setIndex] = useState(0);

    var backgroundTheme = themeCSS(props.theme);

    return (
        <div>
            <div className="epic-button" onClick={() => setOpen(true)} style={{background: backgroundTheme, backgroundSize: "800%"}}>
                        <div className="image-container">
                            <img className="picture1 small" src={props.images[0]}/>
                            <img className="picture2 small" src={props.images[1]}/>
                            <img className="picture3 small" src={props.images[2]}/>
                    </div>
                <img className="play-button gray" src="/iconz/image-gallery.png"/>
                <img className="play-button red" src="/iconz/image-gallery.png"/>
                <div className="text">{props.text}</div>
            </div>

            {isOpen && (
                <Lightbox
                mainSrc={props.galleryImages[photoIndex]}
                nextSrc={props.galleryImages[(photoIndex + 1) % props.galleryImages.length]}
                prevSrc={props.galleryImages[(photoIndex + props.galleryImages.length - 1) % props.galleryImages.length]}
                onCloseRequest={() => setOpen(false)}
                onMovePrevRequest={() =>
                    setIndex((photoIndex + props.galleryImages.length - 1) % props.galleryImages.length)
                }
                onMoveNextRequest={() =>
                    setIndex((photoIndex + 1) % props.galleryImages.length)
                }
                />
            )}
        </div>
    )
}

export default EpicGalleryButton