import React, { useState } from 'react'
import './epic-button.scss';
import themeCSS from './epic-button-themes';
import ImageComponent from '../image-component/image-component';


const EpicActionButton = (props) => {

    var backgroundTheme = themeCSS(props.theme);

    return (
            <div className="epic-button" style={{background: backgroundTheme, backgroundSize: "800%"}} onClick={() => {props.action()}}>
                        <div className="image-container">
                            <ImageComponent className="picture1" src={props.images[0]}/>
                            <ImageComponent className="picture2" src={props.images[1]}/>
                            <ImageComponent className="picture3" src={props.images[2]}/>
                    </div>
                <div className="text">{props.text}</div>
            </div>
    )
}

export default EpicActionButton