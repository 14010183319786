import React from 'react'
import Youtube3D from '../youtube-3D'

const Jeff = (props) => {
    const images = [
        './youtube/jeffsign.png',
        './youtube/jeff.png',
        './youtube/johansceptic.png',
    ]

    return (
    <Youtube3D 
        images={images}
        name="Jeff the Intern"
        youtubeId="BFowbn-CD9I"
        {...props}
    />
  )
}

export default Jeff