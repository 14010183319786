import React from 'react';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import CanSkins from '../other/3D/objects/can-skins';
import './new-main.scss';
import Trailer from '../other/3D/youtube/premade/trailer';
import Cocacentered from '../other/3D/objects/cocacentered';

import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';
import EpicLinkButton from '../../components/epic-button/epic-link-button';

const theme = "new3";

const docImages = [
    process.env.PUBLIC_URL + '/youtube/google-docs-neg.png',
    process.env.PUBLIC_URL + '/youtube/empty.png',
    process.env.PUBLIC_URL + '/youtube/empty.png',
];

const trailerImages = [
    process.env.PUBLIC_URL + '/youtube/coolperson.png',
    process.env.PUBLIC_URL + '/youtube/philipvformation.png',
    process.env.PUBLIC_URL + '/youtube/german.png',
];

const bondImages = [
    process.env.PUBLIC_URL + './youtube/toplane.png',
    process.env.PUBLIC_URL + './youtube/philipbond.png',
    process.env.PUBLIC_URL + './youtube/elrond.png',
]
const newsImages = [
    process.env.PUBLIC_URL + './youtube/iconspaceshipblack.png',
    process.env.PUBLIC_URL + './youtube/philnews.png',
    process.env.PUBLIC_URL + './youtube/empty.png',
]

const fortniteImages = [
    process.env.PUBLIC_URL + '/youtube/interviewpetter.png',
    process.env.PUBLIC_URL + '/youtube/iconinvader.png',
    process.env.PUBLIC_URL + '/youtube/interviewolof.png',
];

const producerQuitsImages = [
    process.env.PUBLIC_URL + '/youtube/ludvigchair.png',
    process.env.PUBLIC_URL + '/youtube/johandespair.png',
    process.env.PUBLIC_URL + '/youtube/beerbottle.png',
];

const bottleImages = [
    process.env.PUBLIC_URL + '/youtube/bottlenes2.png',
    process.env.PUBLIC_URL + '/youtube/bottleanimation2.png',
    process.env.PUBLIC_URL + '/youtube/bottlejap2.png',
];

const wrapImages = [
    process.env.PUBLIC_URL + '/youtube/philipcamera.png',
    process.env.PUBLIC_URL + '/youtube/johanpetterhug.png',
    process.env.PUBLIC_URL + '/youtube/german2.png',
];

const JeffImages = [
    process.env.PUBLIC_URL + '/youtube/jeffsign.png',
    process.env.PUBLIC_URL + '/youtube/jeff.png',
    process.env.PUBLIC_URL + '/youtube/johansceptic.png',
];

const contents = [
    {
        name: "Artistic Consultancy",
        icon: "pacman",
        element: <Infobox title="Artistic Consultancy" text={
        <>
            <p>We're looking for <b>consultants</b> and <b>freelancers</b> to help us with the graphical aspects of our launch. We're currently seeking:<br/>
            <ul>
                <li>Art Direction</li>
                <li>UX Design</li>
                <li>Label Art Design (Gaming Themed)</li>
            </ul>
            If you have expertise in any or more of these areas and are interested in working with us, please contact me at <b>petter@glhf.beer!</b><br/>
            <br/>
            Click the icons below for more information.</p>
        </>}/>,
        element3d: <Trailer/>
    },
    {
        name: "AD & UX",
        icon: "sword",
        element: <Infobox title="AD & UX" text={
        <>
            <p>To consolidate the feel of our content and to make our app and website harmonize with gamers, we're seeking senior AD and UX consultants. This could be one person, this could be two people. <br/><br/>Read more in the document below.</p>
            <EpicLinkButton images={docImages} theme="1" text="LFM AD & UX!" file="/docs/Art-Director-UX-Designer.pdf"/>
        </>}/>,
        element3d: <Cocacentered/>
    },
    {
        name: "Label Designers",
        icon: "beer",
        element: <Infobox title="Label Designers" text={
        <>
            <p>We're going to be making a lot of cool label art for our beer cans and their AR representations. We're looking for game artists with different styles in order to represent different genres and types of gamers. <br/><br/>Read more in the document below. </p>
            <EpicYoutubeButton images={bottleImages} theme="2" text="AR Beer" youtubeId="Fwee970BoHk"/>
            <EpicLinkButton images={docImages} theme="3" text="LFM LD!" file="/docs/GLHF-Label-Artist.pdf"/>
        </>}/>,
        element3d: <CanSkins/>
    },
    {
        name: "Guidelines",
        icon: "spaceinvader",
        element: <Infobox title="Guidelines" text={
        <>
            <p>We're looking to create a mature, down to earth and awesome beverage brand for gamers. Here are some documents containing graphical outlines as well as our thoughts and values. Don't take them too seriously but take them very seriously, if you know what I mean.</p>
            <EpicLinkButton images={docImages} theme="1" text="Brand Identity" file="/docs/GLHF-Brand-Identity.pdf"/>
            <EpicLinkButton images={docImages} theme="2" text="Design Principles" file="/docs/GLHF-Design-Principles.pdf"/>
            <EpicLinkButton images={docImages} theme="3" text="Brand Values" file="/docs/GLHF-Brand-Values.pdf"/>
       </>}/>,
        element3d: <Trailer/>
    },
];

function Artists() {

    return (
        <Slide contents={contents} theme={theme}/>
    );
  }
  
export default Artists;
