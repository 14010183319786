import React from 'react'
import themeCSS from './epic-button-themes';


const EpicButtonSimple = (props) => {

    var backgroundTheme = themeCSS(props.theme);

  return (
    <div className="epic-button simple" style={{background: backgroundTheme, backgroundSize: "800%"}} onClick={() => {props.action()}}>
        <div className="text">{props.text}</div>
    </div>

  )
}

export default EpicButtonSimple