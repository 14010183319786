

import React, { useState } from 'react';
import { Loader } from "@googlemaps/js-api-loader"
import "./gopreview.scss";
import { get } from 'jquery';
import { getQuadrantId, getVisibleQuadrants, getBoundsForQuadrant } from '../../utils/calculations.js';

let requestCount = 0; // Counter to keep track of ongoing requests

function showSpinner() {
  document.getElementById('spinner').style.display = 'block';
}

function hideSpinner() {
  document.getElementById('spinner').style.display = 'none';
}

function requestStarted() {
    requestCount++;

    // Show spinner if first request
    if (requestCount === 1) {
        showSpinner();
    }
}

function requestCompleted() {
    requestCount--;

    if (requestCount === 0) {
        hideSpinner();
    }
}

const GoPreview = () => {

    // const [quadrantSizeMeter, setQuadrantSizeMeter] = useState(1000);
    // const [maxBarsPerQuadrant, setMaxBarsPerQuadrant] = useState(5);
    
    // const loader = new Loader({
    //     apiKey: "AIzaSyDOl0xI9qP5RUSQolJdFxj_2iGzOe1YSjE",
    //     version: "weekly",
    //   });
      
    // loader.load().then(async () => {
    //     const { Map } = await window.google.maps.importLibrary("maps");
    //     const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary("marker");
    //     const { Place } = await window.google.maps.importLibrary("places");
      
    //     const testIconURL = "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"

    //     const map = new Map(document.getElementById("gmap"), {
    //         center: { lat: 59.3293, lng: 18.0686 },
    //         zoom: 8,
    //         mapId: "a7be995dc1bee7fc",
    //     });

    //     const quadrantCache = [];
    //     const displayedQuadrants = [];
        
    //     function handlePlaces(places) {

    //         console.log("place", places);

    //         places.forEach((place) => {
                
    //             if (place.business_status !== "OPERATIONAL" && place.businessStatus !== "OPERATIONAL") return;

    //             const position = place.geometry?.location || place.location;

    //             const key = `${position.lat()},${position.lng()}`;
                
    //             const quadrantId = getQuadrantId(position.lat(), position.lng(), quadrantSizeMeter);

    //             if (!barsByQuadrant[quadrantId]) {
    //                 barsByQuadrant[quadrantId] = createQuadrant(quadrantId);
    //             }

    //             if (barsByQuadrant[quadrantId].bars[key]) {
    //                 return;
    //             }

    //             const marker = new AdvancedMarkerElement({
    //                 map,
    //                 position: position,
    //                 title: place.displayName,
    //             });

    //             const newBar = {marker: marker, placeName: place.displayName, displayed: true};
    //             marker.setMap(map);
    //             barsByQuadrant[quadrantId].bars[key] = newBar;

    //         });
    //     }

    //     async function searchTextBeer(bounds) {
            
    //         requestStarted();
            
    //         const request = {
    //             textQuery: "beer",
    //             fields: ["displayName", "location", "businessStatus"],
    //             includedType: "bar",
    //             locationRestriction: bounds,
    //             language: "en-US",
    //         };

    //         const { places } = await Place.searchByText(request);

    //         handlePlaces(places);

    //         requestCompleted();

    //     }

    //     function displayQuadrant(quadrantId) {
    //         if (!barsByQuadrant[quadrantId]) {
    //             console.error("Quadrant not found", quadrantId);
    //             return;
    //         }

    //         // Display the first bars in the quadrant up to the limit
    //         const bars = barsByQuadrant[quadrantId].bars;
    //         let count = 0;
    //         for (const key in bars) {
    //             if (count >= maxBarsPerQuadrant) {
    //                 break;
    //             }

    //             const bar = bars[key];
    //             if (!bar.displayed) {
    //                 bar.marker.setMap(map);
    //                 bar.displayed = true;
    //                 count++;
    //             }
    //         }
    //         displayedQuadrants.push(quadrantId);
    //         console.log("displayedQuadrants", displayedQuadrants);
    //     }

    //     function hideQuadrant(quadrantId) {
    //         if (!barsByQuadrant[quadrantId]) {
    //             console.error("Quadrant not found", quadrantId);
    //             return;
    //         }

    //         const bars = barsByQuadrant[quadrantId].bars;
    //         for (const key in bars) {
    //             const bar = bars[key];
    //             if (bar.displayed) {
    //                 bar.marker.setMap(null);
    //                 bar.displayed = false;
    //             }
    //         }
    //         displayedQuadrants.splice(displayedQuadrants.indexOf(quadrantId), 1);
    //     }


    //     // map.addListener("zoom_changed", async () => {
    //     //     await handleMarkers();
    //     // });

    //     // map.addListener("dragend", async () => {
    //     //     await handleMarkers();
    //     // });

    //     async function getQuadrantsFromServer(quadrants)
    //     {
    //         requestStarted();

    //         const response = await fetch("http://localhost:8080/quadrants", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(quadrants),
    //         });

    //         const data = await response.json();

    //         requestCompleted();

    //         return data;
    //     }

    //     function isQuadrantVisible(quadrantId) {
    //         const quadrantBounds = getBoundsForQuadrant(quadrantId, quadrantSizeMeter);
    //         const mapBounds = map.getBounds();
            
    //         const quadrantLatLngBounds = new window.google.maps.LatLngBounds(
    //             new window.google.maps.LatLng(quadrantBounds.south, quadrantBounds.west),
    //             new window.google.maps.LatLng(quadrantBounds.north, quadrantBounds.east)
    //         );

    //         return mapBounds.intersects(quadrantLatLngBounds);
    //     }

    //     map.addListener("drag", async () => {

    //         if (map.getZoom() < 14) {
    //             // hide all visible quadrants
    //             for (const quadrantId in displayedQuadrants) {
    //                 hideQuadrant(quadrantId);
    //             }

    //             return;
    //         }

    //         const newQuadrants = getVisibleQuadrants(map.getBounds(), quadrantSizeMeter);

    //         // Create a list of quadrant IDs that need to be hidden
    //         const quadrantsToHide = [];
    //         const quadrantsToLeave = [];
    //         const quadrantsToShow = [];
    //         const quadrantsToFetch = [];

    //         // Identify all now visible quadrants that need to be hidden or left visible
    //         for (const quadrantId in displayedQuadrants) {
    //             if (newQuadrants.includes(quadrantId))
    //                 quadrantsToLeave.push(quadrantId);
    //             else
    //                 quadrantsToHide.push(quadrantId);
    //         }

    //         // Identify all new quadrants that need to be shown or fetched
    //         for (const quadrantId in newQuadrants) {
    //             if (!quadrantsToLeave.includes(quadrantId) && !quadrantsToHide.includes(quadrantId))
    //             {
    //                 if (quadrantCache[quadrantId]) 
    //                     quadrantsToShow.push(quadrantId);
    //                 else 
    //                     quadrantsToFetch.push(quadrantId);        
    //             }
    //         }

    //         // Hide quadrants
    //         for (const quadrantId of quadrantsToHide) {
    //             hideQuadrant(quadrantId);
    //         }

    //         // Show quadrants
    //         for (const quadrantId of quadrantsToShow) {
    //             displayQuadrant(quadrantId);
    //         }

    //         getQuadrantsFromServer(quadrantsToFetch)
    //             .then(async (data) => {
    //                 for (const quadrant in data) {

    //                     quadrantCache[quadrant.id] = quadrant;
    //                     if (isQuadrantVisible(quadrant.id))
    //                         displayQuadrant(quadrant.id);

    //                 }
    //             });
    //     });

    //   });

    return (
        <div>
            <div id="gmap"></div>
            <div id="spinner" className="spinner"></div>
        </div>
    );}


export default GoPreview;
