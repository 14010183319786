import React from 'react';
import Slide from '../../components/slide-new/slide';
import Cocacentered from '../other/3D/objects/cocacentered';
import Infobox from '../../components/presentationbox/infobox';
import CanSkins from '../other/3D/objects/can-skins';
import Controller from '../other/3D/objects/controller';
import Owl from '../other/3D/objects/owl2';
import Map from '../other/3D/objects/treasuremap';
import './new-main.scss';

import Newsletter from '../../components/newsletter/newsletter';
import MainFrontPage from './main-front-page';
import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';

const theme = "new3";

const fortniteImages = [
    process.env.PUBLIC_URL + '/youtube/interviewpetter.png',
    process.env.PUBLIC_URL + '/youtube/iconinvader.png',
    process.env.PUBLIC_URL + '/youtube/interviewolof.png',
];

const producerQuitsImages = [
    process.env.PUBLIC_URL + '/youtube/ludvigchair.png',
    process.env.PUBLIC_URL + '/youtube/johandespair.png',
    process.env.PUBLIC_URL + '/youtube/beerbottle.png',
];

const bottleImages = [
    process.env.PUBLIC_URL + '/youtube/bottlenes2.png',
    process.env.PUBLIC_URL + '/youtube/bottleanimation2.png',
    process.env.PUBLIC_URL + '/youtube/bottlejap2.png',
];

const wrapImages = [
    process.env.PUBLIC_URL + '/youtube/philipcamera.png',
    process.env.PUBLIC_URL + '/youtube/johanpetterhug.png',
    process.env.PUBLIC_URL + '/youtube/german2.png',
];


const contents = [
    {
        name: "GLHF",
        icon: "spaceinvader",
        element: <>
            <Infobox title="GLHF" text={
                    <MainFrontPage/>
                }/>
        </>,
        element3d: <Cocacentered/>
    },
    {
        name: "Beer 2.0",
        icon: "beer",
        element: <Infobox title="Beer 2.0" text={
        <>
            <EpicYoutubeButton images={bottleImages} theme="2" text="AR Beer" youtubeId="Fwee970BoHk"/>
            <p>We are digitalizing beer. You can soon <b>reskin</b> your bottles in AR, play <b>digital beerpong</b> with your friends, or capture your local bar with your <b>clan</b> (Pokemon GO-style).</p> <p>Further on, we're creating digital afterworks and a bar in the metaverse, where you can share a beer sitting next to someone in our physical bars.</p></>}/>,
        element3d: <CanSkins/>
    },
    {
        name: "Community",
        icon: "sword",
        element: <Infobox title="Community" text={
        <>
            <EpicYoutubeButton images={fortniteImages} theme="1" text="Meet us!" youtubeId="bStItpN1h_Q"/>
            <EpicYoutubeButton images={producerQuitsImages} theme="2" text="Producer quits!" youtubeId="tevbBxsLmLw"/>
            <p>GLHF is <b>80%</b> fun and games, and <b>20%</b> super serious. We want to bring a positive, uplifting and mature feel to gaming.</p> <p>We also want to give back to the community. <b>10% of our profits</b> is donated to grass roots gaming. <b>You choose</b> what game you want to sponsor in our app. </p></>}/>,
        element3d: <Controller/>
    },
    {
        name: "Roadmap",
        icon: "spaceship",
        element: <Infobox title="Roadmap" text={<><p>Right now we're preparing for a big <b>crowdfunding campaign</b> in either september or october, depending on some of our partners. We have some amazing collaborations going on, and they need a bit more time.</p><p>We also have big investors knocking on our door, but we want to build this as a community movement and be free to do what the community wants, so this campaign is very important to us.</p><p>If you'd like us to succeed, share this and follow us, or sign up for the newsletter on the next tab!</p></>}/>,
        element3d: <Map/>
    },
    {
        name: "Hello",
        icon: "pacman",
        element: <Infobox title="Hello" 
            text={
                <>
                    <EpicYoutubeButton images={wrapImages} theme="2" text="Almost there!" youtubeId="KtDyK2g4I04"/>
                    <p>We're closing in on our launch. We can't express how hyped we are for this! Sign up to the newsletter to get all the info first!</p>
                    <Newsletter/>
                    <p>Questions? Ideas? Proud to have finished Elden Ring? Slide into our inbox at <span className="color2"><b>hello@glhf.beer</b></span></p>
                </>}/>,
        element3d: <Owl/>
    },
];

function NewMain() {

    return (
        <Slide contents={contents} theme={theme}/>
    );
  }
  
export default NewMain;
