import React, {useState, useEffect } from 'react'
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import './rendering.scss';
import LogoPlane from './objects/logoplane';
import useWindowDimensions from '../../../utils/UseWindowDimensions';
import { useSpring } from '@react-spring/three';
import { ResizeObserver } from "@juggle/resize-observer";

const objectOffsetZ = 20;
var fadeOffset = 0;

console.log("Hello Computer Person™. You're cool. Love you.")

const CameraComp = (props) => {

    const { camera } = useThree();

    const [{animationOffsetX, animationOffsetZ}, animationApi]  = useSpring(() => (
      { 
        animationOffsetX: 0,
        animationOffsetZ: 20 
      }))

    function preAnimation() {
      props.introAnimation.started = true;
      animationApi.start(
        {
          to: {
            animationOffsetX: 300,
            animationOffsetZ: -500
          },
          config: {
            duration: 100
          },
          onRest: () => mainAnimation()
        });
    }

    function mainAnimation() {
      props.introAnimation.preDone = true;
      animationApi.start(
        {
          to: {
            animationOffsetX: 0,
            animationOffsetZ: 0
          },
          config: {
            mass:1,
            tension: 280,
            friction: 120,
            precision: 0.001
          },
          onRest: () => animationDone()
        });
    }

    function animationDone() {
      props.introAnimation.done = true
    }

    useFrame(() => {
        props.percentage.value = props.percentageSpring.get();

        if (props.introAnimation.ready && !props.introAnimation.started)
          preAnimation();

        camera.position.set(
          4 + props.sceneOffset[0] + animationOffsetX.get(),
          16.7 + props.sceneOffset[1],
          10 - props.percentage.value / 100 * (props.contents.length - 1) * objectOffsetZ + props.sceneOffset[2] + animationOffsetZ.get()
        )
    })
  
  return (
      <React.Fragment></React.Fragment>
    )
}

const introAnimation = 
{
  ready: false,
  started: false,
  preDone: false,
  done: false
}

const RenderTest = (props) => {

  const { width } = useWindowDimensions();

  const [sceneOffset, setSceneOffset] = useState([0, 0, 0]);

  useEffect(() => {
      if (width > 1000)
      {
        setSceneOffset([0, 0, 0]);
        fadeOffset = 0;
      }
      else
      {
        setSceneOffset([1.5, 0.8, 3]);
        fadeOffset = 5;
      }

  },[width])

  useEffect(() =>{
    introAnimation.ready = true;
  }, [])

  return (
    <Canvas resize={{ polyfill: ResizeObserver }}>
      <PerspectiveCamera makeDefault fov={50} near={0.1} far={1000} position={[4, 17, 10]} rotation={[-0.15, 0.4, 0]}/> 
      <CameraComp introAnimation={introAnimation} percentage={props.percentage} percentageSpring={props.percentageSpring} contents={props.contents} sceneOffset={sceneOffset}/>

        {props.contents.map((content, i) => {
            return React.cloneElement(content.element3d, 
              { 
                introAnimation: {introAnimation},
                fadeStart:11 + sceneOffset[2] + fadeOffset, 
                fadeEnd:19 + sceneOffset[2] + fadeOffset, 
                position:[0, 15, -i*objectOffsetZ], 
                offset:i*objectOffsetZ + sceneOffset[2], 
                objectOffsetZ:objectOffsetZ, 
                key:"content"+i, 
                percentage:props.percentage,
                visible: true
              })
        })}
      <LogoPlane introAnimation={introAnimation}/>
      <ambientLight intensity={1.1} />
      <spotLight intensity={0.4} position={[0, 0, 100]} angle={0.3} castShadow />
    </Canvas>
) 
}

export default RenderTest
