import React from 'react'
import BeerIconBox from '../../components/beer-icon-box/beer-icon-box';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import CraftPreviewer from '../other/3D/craft-previewer';

const mainBeerList = [
    {
        name: "White Mage",
        label: "/canlabels/whitemage.jpg",
        moodboard: "/beers/whitemage/White Mage Moodboard.jpg",
        infoImage: "/beers/whitemage/White Mage Info.jpg",
        icon: "/iconz/pixel/chest.png",
    },
    {
        name: "Black Mage",
        label: "/canlabels/whitemage2.jpg",
        moodboard: "/beers/blackmage/Black Mage Moodboard.jpg",
        infoImage: "/beers/blackmage/Black Mage Info.jpg",
        icon: "/iconz/pixel/beer.png",
    },
];

const beerChanger = {
    changePreview: (beer) => {
        console.log("changePreview needs to be set in craft previewer");
    }
}

const contents = [
    {
        name: "GLHF",
        icon: "spaceinvader",
        element: <>
            <Infobox title="Beers" text={
                <>
                    <h3>Main Beers</h3>
                    <BeerIconBox beerList={mainBeerList} beerChanger={beerChanger}/>
                    <h3>Side Beers</h3>
                    <BeerIconBox beerList={mainBeerList}/>
                </>
                }/>
        </>,
        element3d: <CraftPreviewer beerChanger={beerChanger}/>
    },
];



const Craft = () => {
  return (
    <Slide contents={contents} theme="new3"/> 
  )
}

export default Craft