import React from 'react'
import './beer-icon-box.scss'

const BeerIconBox = ({beerList, beerChanger}) => {
  return (
    <div className="beer-icon-box">
      {beerList.map((beer, index) => (
        <div className="beer-icon" key={index}>
          <img src={beer.icon} alt={beer.name} title={beer.name} onClick={() => beerChanger.changePreview(beer)}/>
        </div>
      ))}
    </div>
  )
}

export default BeerIconBox