import React from 'react';
import Slide from '../../components/slide-new/slide';
import Infobox from '../../components/presentationbox/infobox';
import CanSkins from '../other/3D/objects/can-skins';
import './new-main.scss';
import Trailer from '../other/3D/youtube/premade/trailer';
import Bond from '../other/3D/youtube/premade/bond';
import Interview from '../other/3D/youtube/premade/interview';


import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';
import Jeff from '../other/3D/youtube/premade/jeff';
import AppGalleryButton from '../premade/app-gallery-button';

const theme = "new3";

const trailerImages = [
    process.env.PUBLIC_URL + '/youtube/coolperson.png',
    process.env.PUBLIC_URL + '/youtube/philipvformation.png',
    process.env.PUBLIC_URL + '/youtube/german.png',
];

const bondImages = [
    process.env.PUBLIC_URL + './youtube/toplane.png',
    process.env.PUBLIC_URL + './youtube/philipbond.png',
    process.env.PUBLIC_URL + './youtube/elrond.png',
]
const newsImages = [
    process.env.PUBLIC_URL + './youtube/iconspaceshipblack.png',
    process.env.PUBLIC_URL + './youtube/philnews.png',
    process.env.PUBLIC_URL + './youtube/empty.png',
]

const fortniteImages = [
    process.env.PUBLIC_URL + '/youtube/interviewpetter.png',
    process.env.PUBLIC_URL + '/youtube/iconinvader.png',
    process.env.PUBLIC_URL + '/youtube/interviewolof.png',
];

const producerQuitsImages = [
    process.env.PUBLIC_URL + '/youtube/ludvigchair.png',
    process.env.PUBLIC_URL + '/youtube/johandespair.png',
    process.env.PUBLIC_URL + '/youtube/beerbottle.png',
];

const bottleImages = [
    process.env.PUBLIC_URL + '/youtube/bottlenes2.png',
    process.env.PUBLIC_URL + '/youtube/bottleanimation2.png',
    process.env.PUBLIC_URL + '/youtube/bottlejap2.png',
];

const wrapImages = [
    process.env.PUBLIC_URL + '/youtube/philipcamera.png',
    process.env.PUBLIC_URL + '/youtube/johanpetterhug.png',
    process.env.PUBLIC_URL + '/youtube/german2.png',
];

const JeffImages = [
    process.env.PUBLIC_URL + '/youtube/jeffsign.png',
    process.env.PUBLIC_URL + '/youtube/jeff.png',
    process.env.PUBLIC_URL + '/youtube/johansceptic.png',
];


const contents = [
    {
        name: "Beer 2.0",
        icon: "beer",
        element: <Infobox title="Beer 2.0" text={
        <>
            <AppGalleryButton/>
            <EpicYoutubeButton images={bottleImages} theme="2" text="AR Beer" youtubeId="Fwee970BoHk"/>
            <p>We're the world's first digital beer. Through our app, you will be able to connect your physical experience to our digital ecosystem. It's going to be lit!</p>
       </>}/>,
        element3d: <CanSkins/>
    },
    {
        name: "Promo Trailer",
        icon: "sword",
        element: <Infobox title="Promo Trailer" text={
        <>
            <EpicYoutubeButton images={trailerImages} theme="1" text="Epic Trailer!" youtubeId="angbWa4bslU"/>
            <p>Around <b>the end of this year</b> we will be launching our crowdfunding campaign. We're going to be communicating with gamers on all kinds of platforms, but in the end, we're sending everyone to our crowdfunding page, and the crown jevel of that page will be our crowdfunding trailer, which we're kinda proud of...</p></>}/>,
        element3d: <Trailer/>
    },
    {
        name: "Segments",
        icon: "spaceship",
        element: <Infobox title="Gamer Segments" text={
        <>
            <p>We've divided gamers into a bunch of categories, and we're creating targeted content for each category. We want each gamer category to feel welcome and greeted through their own channels and language.</p>
            <br/>
            <EpicYoutubeButton images={bondImages} theme="1" text="Board Gamers" youtubeId="zn5LWdKlGTU"/>
            <EpicYoutubeButton images={newsImages} theme="2" text="Co-op Bros" youtubeId="Jcrk5PY_FmQ"/>
        </>}/>,
        element3d: <Bond/>
    },
    {
        name: "Social Media",
        icon: "spaceinvader",
        element: <Infobox title="Social Media" text={
        <>
            <p>We're a community movement, and we want the community to know us. From the start of the crowdfunding campaign forward, we will share our journey on social media and invite the community to join us for events and streams.</p>
            <EpicYoutubeButton images={fortniteImages} theme="1" text="Meet us!" youtubeId="bStItpN1h_Q"/>
            <EpicYoutubeButton images={producerQuitsImages} theme="2" text="Producer quits!" youtubeId="tevbBxsLmLw"/>
            <EpicYoutubeButton images={wrapImages} theme="3" text="Almost there!" youtubeId="KtDyK2g4I04"/>
        
        </>}/>,
        element3d: <Interview/>
    },
    {
        name: "Youtube",
        icon: "pacman",
        element: <Infobox title="Youtube" text={
        <>
            <p>We don't do commercials. We do content. We love content. Fun, good and inspiring content. Our Youtube channel will be like any other gaming channel, and we're going to be creating series, videos and contents in all kinds of relating to GLHF and gaming.</p>
            <EpicYoutubeButton images={JeffImages} theme="1" text="Jeff the Intern" youtubeId="BFowbn-CD9I"/>
        </>}/>,
        element3d: <Jeff/>
    },

];

function Content() {

    return (
        <Slide contents={contents} theme={theme}/>
    );
  }
  
export default Content;
