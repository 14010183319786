import React from 'react';
import Slide from '../../components/slide-new/slide';
import Textblock from '../../components/textblock/textblock';
import Imageblock from '../../components/imageblock/imageblock';
import Infobox from '../../components/presentationbox/infobox';
import Cocacentered from '../other/3D/objects/cocacentered';
import Cookie from '../other/3D/objects/cookie';
import CanSkins from '../other/3D/objects/can-skins';

const theme = 'new3';

var randomcat = "https://cataas.com/cat/says/Random%20Internet%20Cat?" + Date.now();

const contents = [
    {
        name: "App",
        icon: "spaceinvader",
        element: <>
            <Infobox title="Upcoming App" text={
                <>
                    <p>The app will be the key to our ecosystem, hold all your beer tokens, beer skins, avatar equipment and much more.</p>
                    <p>Right now, our tech team is crunching on a prototype for our crowdfunding campaign. You'll be able to do quests, play some silly games, vote for upcoming beers, designs, events, and of course send a digital beer to a friend.</p>
                </>
                }/>
        </>,
        element3d: <CanSkins/>
    },
]

function Easter(props) {  


    return (
        <Slide contents={contents}
        theme="new3"/> 
    );
  };
  
export default Easter;
