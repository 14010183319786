/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useTexture, PresentationControls, Mask, useMask } from '@react-three/drei'
import { useFrame, useThree } from "@react-three/fiber";
import Mobile from './simplephone';
import { MeshStandardMaterial, Clock } from 'three';
import { useSpring } from '@react-spring/three';

const time = new Clock();

var t = {value: null};

const CanSkins = ({ ...props }) => {

  const group = useRef();
  const { camera } = useThree();

  const stencils = { stencil: null, stencilInv: null, added: false }

  const opacity = {value: 0};

  function setCanTexture (texture)
  {
      t.value = texture;
  }

  useFrame(() => {

    if (props.introAnimation.done == false) return;

    const mesh1Position = camera.position;
    const mesh2Position = group.current.position;
    const distance = mesh1Position.distanceTo(mesh2Position);
    
    var o = 0;
    if (distance < props.fadeStart) o = 1; 
    if (distance > props.fadeEnd) o = 0;
    if (distance > props.fadeStart && distance < props.fadeEnd) o = 1 - (distance - props.fadeStart) / (props.fadeEnd - props.fadeStart);

    opacity.value = o;
    //materialCan.opacity = o;

    //can.current.position.set(-offset.get() / 5, 0.9, -offset.get() / 3)
  })

  const { nodes } = useGLTF('/cocacentered.gltf');

  const texture = useTexture("/canlabels/default.jpg", (texture) => {
    texture.flipY = false
  });

  return (
    <group {...props} dispose={null} ref={group} position={props.position}>
        <PresentationControls
          global={false} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={false} // Snap-back to center (can also be a spring config)
          speed={2.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[0, 0, 0]} // Default rotation
          polar={[-Math.PI / 4, Math.PI / 4]} // Vertical limits
          azimuth={[-Math.PI / 4, Math.PI / 4]} // Horizontal limits
          config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        >
          <Can stencils={stencils} introAnimation={props.introAnimation} opacity={opacity} geometry={nodes.Cylinder001.geometry} texture={t} defaultTexture={texture}/>
          <Mobile stencils={stencils} introAnimation={props.introAnimation} opacity={opacity} setCanTexture={setCanTexture}/>
          {/* <mesh geometry={textGeo} material={materialText}  position={[0, 0, 1.22]}/> */}
        </PresentationControls>
    </group>
  )
};

const Can = (props) => {

  const [{offset}, setSpeed] = useSpring(() => ({ offset: 0 }))
  const can = useRef();
  const canNoSkin = useRef();

  const stencil = useMask(1);
  const stencilInv = useMask(1, true);

  const mat = new MeshStandardMaterial ({
    metalness: 0.7,
    roughness: 0.35,
    envMapIntensity: 0.7,
    map: props.defaultTexture,
    transparent: true,
    opacity: 0,
    ...stencil
  })

  const matNoSkin = new MeshStandardMaterial ({
    metalness: 0.7,
    roughness: 0.35,
    envMapIntensity: 0.7,
    map: props.defaultTexture,
    transparent: true,
    opacity: 0,
    ...stencilInv
  })

  props.stencils.stencil = stencil;
  props.stencils.stencilInv = stencilInv;

  useFrame(() => {

    if (props.texture.value == null) return;

    if (mat.map != props.texture.value)
    {
      mat.map = props.texture.value;
      offset.set(1);
      offset.start(0); 
    }  
    
  })

  //const [pos, setPos] = useState([0, 0, 0])
  //const [rot, setRot] = useState([0, 0, 0])

  useFrame((state) => {

    if (props.introAnimation.done == false) return;

    mat.opacity = props.opacity.value;
    matNoSkin.opacity = props.opacity.value;
    if (props.opacity.value == 0) return;

    let defaultYRotationOffset = 0;
    if (props.texture.value == null) defaultYRotationOffset = 1.8;

    let x = 0 + Math.sin(time.getElapsedTime() * 1.4 - 0.2) / 40 + Math.sin(time.getElapsedTime() * 1.1 - 0.2) / 40 - offset.get() / 12;
    let y = 0.87 + Math.sin(time.getElapsedTime() * 0.5) / 20;
    let z = 0 + Math.sin(time.getElapsedTime() * 1.4 - 0.2) / 30 - offset.get() / 12;

    can.current.position.set(x, y, z);
    canNoSkin.current.position.set(x, y, z);
    //setPos([x, y, z]);

    let yRot = -1.9 + Math.sin(time.getElapsedTime() * 0.5) / 2 + defaultYRotationOffset;
    let defyRot = -1.9 + Math.sin(time.getElapsedTime() * 0.5) / 2 + 1.8;
    can.current.rotation.set(0, yRot, 0);
    canNoSkin.current.rotation.set(0, defyRot, 0);
    //setRot([0, yRot, 0]);
  })

  return (
    <group>

      <Mask id={1} position={[0.85, 0.5, 2.2]} rotation={[-0.1,0.5,0]}>
        <planeBufferGeometry attach="geometry" args={[3.5, 6]}/>
      </Mask>

      <mesh ref={can} material={mat} geometry={props.geometry}  scale={0.45}></mesh>
      <mesh ref={canNoSkin} material={matNoSkin} geometry={props.geometry}  scale={0.45}></mesh>

      {/* <mesh ref={can2} {...stencil} {...props} material={can2Material} scale={0.45} /> */}
    </group>
  )
}


export default CanSkins;

useGLTF.preload('/cocacentered.gltf')
