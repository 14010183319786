import React, { useState, useEffect, useCallback } from 'react'
import Menubutton from './menubutton';
import './presentationbox.scss';
import PresentationSection from './presentationsection';
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import ReactGA from "react-ga4";

const Presentationbox = (props) => {

    function setActiveButton(i) {
        setActive(i);
        props.scrollTo(i);
    }
    const [activeButton, setActive] = useState(0);
    
    const [hidden, setHidden] = useState(false);

    function toggleHide() {
        ReactGA.event({
            category: "Mobile Toggle",
            action: "Hide Toggle Click",
            label: !hidden, // optional
            pageUrl: window.location.href,
            pageTitle: document.title
          });
        setHidden(!hidden);
    }

    const scrollToFast = useCallback(() => {
        console.log("scrolling fast to " + activeButton);
        scrollIntoView(document.getElementById(`section${activeButton}`), {
          duration: 0,
        });
    }, [activeButton]);
    
    useEffect(() => {
        // Add the event listener when the component is mounted
        window.addEventListener('resize', scrollToFast);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', scrollToFast);
        };
    }, [scrollToFast]); // Add scrollToFast as a dependency

    return (
        <div className="presentation-wrapper">
            <div className='hide-button' onClick={toggleHide}>
                <div className={"hide-bg" + (hidden ? " hide" : "")}></div>
                <div className={"hide-option" + (hidden ? "" : " active")}><b>2D</b></div>
                <div className={"hide-option" + (hidden ? " active" : "")}><b>3D</b></div>
            </div>
            <div className="presentation-box">
                <div className={hidden ?"box-wrapper hidden" : "box-wrapper"}>
                    <div className="presentation-info">
                        {props.contents.map((content, i) => {
                            return <PresentationSection key={i} id={"section" + i} element={content.element}/>
                        })}
                    </div>
                    <div className="presentation-menu">
                        {props.contents.length > 1 ? 
                        props.contents.map((content, i) => {
                                return <Menubutton 
                                    activeButton={activeButton} 
                                    slideAnimationDuration={props.slideAnimationDuration} 
                                    setActiveButton={setActiveButton} 
                                    key={i} 
                                    i={i} 
                                    icon={content.icon} 
                                    name={content.name}/>;
                            })
                            : <></>}
                    </div>
                </div>
            </div>
        </div>
    )
    };

export default Presentationbox