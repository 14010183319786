import React from 'react'
import { useTexture } from "@react-three/drei";
import { MeshBasicMaterial, ShapeGeometry, BoxGeometry } from 'three';
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import { useFrame } from '@react-three/fiber';

var geometries = [];
const loader = new SVGLoader();
const material = new MeshBasicMaterial({
    color:"black",
    depthWrite: false,
    transparent: true,
    opacity: 0,
})

loader.load('/glhf-pos.svg',
    function ( data ) {
        const paths = data.paths;

        for ( let i = 0; i < paths.length; i ++ ) {

            const path = paths[ i ];
            const shapes = SVGLoader.createShapes( path );

            for ( let j = 0; j < shapes.length; j ++ ) {
                const shape = shapes[ j ];
                const geometry = new ShapeGeometry( shape );
                geometries.push( geometry );
            }
        }
    }
);

const boxgeo = new BoxGeometry(1,1,1); 

const LogoPlane = (props) => {

    useFrame(() => {
        if (props.introAnimation.preDone == true) material.opacity = 1;
    })

    return (
        <group position={[-250, 300, -150]} scale={[1.54, -1.54, 1.54]} rotation={[0, 0, 0]}>
            <mesh geometry={geometries[0]} material={material} />
            {geometries.map((geometry, i) => {
                return <mesh key={i} geometry={geometry} material={material} />
            })}
        </group>
    )
}

export default LogoPlane