/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from 'react'
import { PresentationControls, Text, useGLTF } from '@react-three/drei'
import { Clock, Vector3, MeshStandardMaterial, TextureLoader, sRGBEncoding } from 'three'
import { useFrame } from '@react-three/fiber';
import { useSpring } from 'react-spring';

const time = new Clock();

const boxMat = new MeshStandardMaterial(
  {
      color: "#000000",
      transparent: true
  }
);
const textMat = new MeshStandardMaterial(
  {
      color: "#FFFFFF",
      transparent: true
  }
)

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('cookie/cookie.gltf')

  const scenePos = new Vector3(
    props.position[0],
    props.position[1],
    props.position[2],
  )

  const texture = new TextureLoader().load( "/cookie/textures/Cookie1A_baseColor.jpeg" );
  texture.encoding = sRGBEncoding;
  const normal = new TextureLoader().load( "/cookie/textures/Cookie1C_normal.jpeg" );
  normal.encoding = sRGBEncoding;

  const material = new MeshStandardMaterial( {
    map: texture,
    normalMap: normal,
  })

  const [{offset}, setOffset] = useSpring(() => ({ offset: 0 }))

  useFrame(() => {

    let x = Math.sin(time.getElapsedTime() * 0.7 - 0.2) / 4 + Math.sin(time.getElapsedTime() * 0.6 - 0.2) / 4;
    let y = Math.sin(time.getElapsedTime() * 0.25) / 20;
    let z = Math.sin(time.getElapsedTime() * 0.7 - 0.2) / 3;

    group.current.position.set(scenePos.x + x / 2, scenePos.y + y / 2, scenePos.z + z / 2- offset.get());

    let xRot = 0.8 + Math.sin(time.getElapsedTime() * 0.3) / 10;
    let yRot = 0 + Math.sin(time.getElapsedTime() * 0.5) / 10;
    group.current.rotation.set(xRot, yRot, 0);

  })

  var cookieClickFunction = {f: null};

  function clicked() {
    cookieClickFunction.f();
    offset.set(.08);
    offset.start(0); 
  }

  return (
      <group ref={group} {...props} dispose={null}>
        <TextBanner cookieClickFunction={cookieClickFunction}/>
        <PresentationControls
          global={false} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={false} // Snap-back to center (can also be a spring config)
          speed={2.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[0, 0, 0]} // Default rotation
          polar={[-Infinity, Infinity]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        >
          <mesh onClick={() => {clicked();}} geometry={nodes.Cookie1_LOD0.geometry} material={material} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
          <ambientLight intensity={0.5} />
        </PresentationControls>
      </group>
  )
}

const TextBanner = ({cookieClickFunction}) => {
  
  const [cookies, setCookies] = useState(0);

  function clickFunc() {
    setCookies(cookies + 1);
  }
  cookieClickFunction.f = clickFunc;

  return (
    <React.Fragment>
      <mesh position={[-1, -5.1, -0.1]} rotation={[-1.2, 0, 0]} material={boxMat}>
      <planeBufferGeometry args={[5.3,1]}/>
      </mesh>
      <Text material={textMat} anchorX="center" fontSize={0.5} position={[-1, -4.95, 0.0]} rotation={[-1.2, 0, 0]} color="white">
          Cookies: {cookies}
      </Text>
    </React.Fragment>
  )

}

useGLTF.preload('cookie/cookie.gltf')
