import React, {useState} from 'react'
import EpicYoutubeButton from '../../components/epic-button/epic-youtube-button';
import EpicButtonSimple from '../../components/epic-button/epic-button-simple';
import EpicActionButton from '../../components/epic-button/epic-action-button';

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

function simulateMouseClick(element){
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
      })
    )
  );
}

const trailerImages = [
    process.env.PUBLIC_URL + '/youtube/coolperson.png',
    process.env.PUBLIC_URL + '/youtube/philipvformation.png',
    process.env.PUBLIC_URL + '/youtube/german.png',
];

const messageImages = [
    process.env.PUBLIC_URL + '/youtube/iconinvaderblack.png',
    process.env.PUBLIC_URL + '/youtube/pettermessage.png',
    process.env.PUBLIC_URL + '/youtube/iconbeerblack.png',
];

const MainFrontPage = () => {

    const [isOpen, setOpen] = useState(false)

    function collaborateAction() {
        window.location.href = "/collaborate";
    }
    
    function newsletterAction() {
        var element = document.querySelector('div[id="button4"]');
        simulateMouseClick(element);
    }
    
  return (
      <React.Fragment>
                    <p>Gaming with good friends and a beer is an absolute treat when you start getting older. We're going to supercharge those moments, build a great community and sponsor grassroots gaming.</p>
                    <br/>
                    <EpicYoutubeButton images={trailerImages} theme="1" text="Epic Trailer!" youtubeId="angbWa4bslU"/>
                    <EpicActionButton images={messageImages} theme="2" text="Newsletter" youtubeId="Wak_mKIVe6I" action={newsletterAction}/>
      </React.Fragment>
  )
}

export default MainFrontPage