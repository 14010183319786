import React from 'react'
import Youtube3D from '../youtube-3D'

const Trailer = (props) => {
    const images = [
        './youtube/coolperson.png',
        './youtube/philipvformation.png',
        './youtube/german.png',
    ]

    return (
    <Youtube3D 
        images={images}
        name="Crowdfunding Trailer"
        youtubeId="angbWa4bslU"
        {...props}
    />
  )
}

export default Trailer